import { FC, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { runDesignNavigation } from '@/helpers/runDesigns/constants';

import ListWithActiveTab from '@/components/common/ListWithActiveTab';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';

import SampleTypeCard from './SampleTypeCard.tsx';

import { RunDesignContext } from '../../context';
import { EDesignStep } from '../../types';

const SampleTypesBlock: FC = () => {
  const appDispatch = useAppDispatch();
  const { goToNextStep, currentStep, goToStep } = useRunDesignLocation();
  const { overrideCustomFooterConfig, clearCustomFooterConfig, handleUpdateRunDesign, isSavingRunDesign } =
    useContext(RunDesignContext);
  const confirmationModal = useConfirmationModalContext();

  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const isEditFieldsHaveChanges = useSelector(experimentRunDesignSelectors.selectIsEditFieldsHaveChanges);
  const assayComponentList = useSelector(experimentRunDesignSelectors.selectAssayComponentList);
  const isSomeSamplesWithoutName = useSelector(experimentRunDesignSelectors.isSomeSamplesWithoutName);
  const isAllLanesHasPreLabelingEX = useSelector(experimentRunDesignSelectors.selectIsAllLanesHasPreLabelingEX);
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);
  const runDesignCardIndexExpand = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexExpand);

  const isSampleNamesGenerated = useSelector(experimentRunDesignSelectors.selectIsSampleNamesGenerated);

  const isCellKillingInAssays = useMemo(
    () => !!assayComponentList.find((assay) => assay.__typename === 'CellKilling'),
    [assayComponentList]
  );

  const isDisplayPleLabeledDataConfirmationModal = useMemo(
    () => !isAllLanesHasPreLabelingEX && isCellKillingInAssays,
    [isEditFieldsHaveChanges, isAllLanesHasPreLabelingEX, isCellKillingInAssays]
  );

  const lanesCount = useMemo(() => currentEditFields.schema?.lanes?.length ?? 0, [currentEditFields.schema?.lanes]);

  const showFunctionBlock = useMemo(
    () => cellTypesCount >= 2 && lanesCount >= 2 && isCellKillingInAssays,
    [isCellKillingInAssays, cellTypesCount, lanesCount]
  );

  const sampleTypesBlockRef: MutableRefObject<Nullable<HTMLDivElement>> = useRef(null);

  const handleEditMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexEdit === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(newIndex));
    },
    [runDesignCardIndexEdit]
  );

  const handleExpandMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexExpand === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexExpand(newIndex));
    },
    [runDesignCardIndexExpand]
  );

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: sampleTypesBlockRef,
      isEditMode: someRunDesignCardIsEdit,
    });
  }, [overrideCustomFooterConfig, sampleTypesBlockRef.current, someRunDesignCardIsEdit]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      isEditMode: someRunDesignCardIsEdit,
      cancel: {
        clickHandler: () => appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1)),
      },
    });
  }, [someRunDesignCardIsEdit]);

  const showSampleNamesConfirmationModal = useCallback(
    async (successCallback: () => void) => {
      if (!handleUpdateRunDesign) return;
      if (!isSomeSamplesWithoutName) {
        handleUpdateRunDesign(successCallback);
        return;
      }
      const isConfirmed = await confirmationModal.onOpen({
        confirmationText: `Do you want to proceed further with automated 
sample labels or cancel to go back to previous 
view to provide your labels for the sample?
`,
        approveButtonText: 'Proceed',
        cancelButtonText: 'Cancel',
      });

      if (isConfirmed) {
        appDispatch(experimentRunDesignActions.generateSampleNamesForNextSteps());
      }
    },
    [isSomeSamplesWithoutName, handleUpdateRunDesign]
  );

  const showPleLabeledDataConfirmationModal = useCallback(
    async (successCallback: () => void) => {
      if (!handleUpdateRunDesign) return;
      try {
        const isConfirmed = await confirmationModal.onOpen({
          confirmationText: `Please define a pre-label for cell-type to use for subtractive caging`,
          approveButtonText: 'Continue anyway',
          cancelButtonText: 'Cancel',
        });

        if (!isConfirmed) return;

        if (isSomeSamplesWithoutName) {
          await showSampleNamesConfirmationModal(successCallback);
          return;
        }
        handleUpdateRunDesign(successCallback);
      } catch (error) {
        console.warn(error);
      }
    },
    [isSomeSamplesWithoutName, handleUpdateRunDesign]
  );

  const newClickHandler = useCallback(
    async (successCallback: () => void) => {
      if (!handleUpdateRunDesign) return;

      if (isDisplayPleLabeledDataConfirmationModal) {
        await showPleLabeledDataConfirmationModal(successCallback);
        return;
      }
      if (isSomeSamplesWithoutName) {
        await showSampleNamesConfirmationModal(successCallback);
        return;
      }
      handleUpdateRunDesign(successCallback);
    },
    [
      handleUpdateRunDesign,
      isSomeSamplesWithoutName,
      isDisplayPleLabeledDataConfirmationModal,
      showPleLabeledDataConfirmationModal,
      showSampleNamesConfirmationModal,
    ]
  );

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    const isDisplayOneOfModals = isSomeSamplesWithoutName || isDisplayPleLabeledDataConfirmationModal;

    if (!handleUpdateRunDesign || (!isEditFieldsHaveChanges && !isDisplayOneOfModals)) return;

    overrideCustomFooterConfig({
      next: {
        clickHandler: () => {
          newClickHandler(goToNextStep);
        },
      },
      navigation:
        currentStep !== EDesignStep.details
          ? runDesignNavigation.map(({ step, label }) => ({
              step,
              label,
              clickHandler: () => {
                const successCallback = () => goToStep(step);

                newClickHandler(successCallback);
              },
              isCurrent: step === currentStep,
              disabled: isSavingRunDesign,
            }))
          : undefined,
    });

    return () => {
      clearCustomFooterConfig();
    };
  }, [
    overrideCustomFooterConfig,
    clearCustomFooterConfig,
    isDisplayPleLabeledDataConfirmationModal,
    handleUpdateRunDesign,
    isSomeSamplesWithoutName,
    showSampleNamesConfirmationModal,
  ]);

  useEffect(() => {
    if (!isSampleNamesGenerated || !handleUpdateRunDesign) return;
    handleUpdateRunDesign(goToNextStep);

    return () => {
      if (clearCustomFooterConfig) {
        clearCustomFooterConfig();
      }

      if (!isSampleNamesGenerated) return;
      appDispatch(experimentRunDesignActions.setIsSampleNamesGenerated(false));
    };
  }, [handleUpdateRunDesign, isSampleNamesGenerated, clearCustomFooterConfig]);

  const activeCardIndex = useMemo(() => {
    if (runDesignCardIndexEdit >= 0) {
      return runDesignCardIndexEdit;
    }

    return runDesignCardIndexExpand;
  }, [runDesignCardIndexEdit, runDesignCardIndexExpand]);

  const cardListContent = useMemo(
    () =>
      Array.from({ length: cellTypesCount }, (_, index) => {
        const key = index;
        return (
          <SampleTypeCard
            key={key}
            runDesignCardIndex={index}
            handleExpandMode={handleExpandMode}
            handleEditMode={handleEditMode}
            isEditMode={runDesignCardIndexEdit === index}
            isExpandMode={runDesignCardIndexExpand === index}
            showFunctionBlock={showFunctionBlock}
          />
        );
      }),
    [cellTypesCount, runDesignCardIndexEdit, runDesignCardIndexExpand, handleExpandMode, handleEditMode]
  );

  return (
    <ListWithActiveTab
      customListRef={sampleTypesBlockRef}
      activeCardIndex={activeCardIndex}
      cardContentList={cardListContent}
    />
  );
};

export default SampleTypesBlock;
