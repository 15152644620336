import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { CellFunction, CellType, Consumable, Lane, Reagent } from '@/graphql/API';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignCellType,
  TRunDesignConsumable,
  TRunDesignLane,
} from '@/store/slices/experimentRunDesign';

import { LANE_LETTER_NAME_LIST } from '@/helpers';
import { RunDesignContext } from '@/pages/experiment-run-design/context';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { isCompoundConsumable, isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ReagentHeader from '@/components/runDesign/ReagentComponent/ReagentHeader';

import EditCellTypeName from './components/EditCellTypeName';
import EditCellTypeFunction from './components/EditCellTypeFunction';
import EditCellTypePreTreatment from './components/EditCellTypePreTreatment';
import EditCellTypePreLabeling from './components/EditCellTypePreLabeling';

import styles from './EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeProps = {
  runDesignCardIndex: number;
  showFunctionBlock?: boolean;
};

const EditCellType: FC<TEditCellTypeProps> = ({ runDesignCardIndex, showFunctionBlock }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const isPreLabelingEX = useSelector(experimentRunDesignSelectors.selectIsPreLabelingEX(runDesignCardIndex));
  const isPreTreatmentEX = useSelector(experimentRunDesignSelectors.selectIsPreTreatmentEX(runDesignCardIndex));

  const [editableLaneList, setEditableLaneList] = useState<Partial<TRunDesignLane>[]>([]);

  const isSomeCellNameDefined = useMemo(
    () =>
      editableLaneList?.some(
        (lane) =>
          !!lane.cellTypes?.find((cellType, index) => index === runDesignCardIndex && !!cellType?.cellIndex?.name)
      ) ?? false,
    [editableLaneList]
  );

  const changeCellType = (laneIndex: number, newCellTypePart: DeepPartial<CellType>) => {
    setEditableLaneList((prev) => {
      const newLaneList = structuredClone(prev);
      const lane = newLaneList[laneIndex];

      if (lane) {
        if (!lane.cellTypes) {
          lane.cellTypes = [];
        }
        const cellType = lane.cellTypes[runDesignCardIndex];
        const currentCellIndex = cellType?.cellIndex ?? null;

        const updatedCellIndex = newCellTypePart?.cellIndex
          ? {
              ...(currentCellIndex ?? {}),
              ...newCellTypePart.cellIndex,
            }
          : currentCellIndex;

        lane.cellTypes[runDesignCardIndex] = cellType
          ? { ...cellType, ...newCellTypePart, cellIndex: updatedCellIndex }
          : { __typename: 'CellType', name: '', function: CellFunction.EFFECTOR, ...newCellTypePart };
        newLaneList[laneIndex] = lane;
      }
      return newLaneList;
    });
  };

  const tableData = useMemo(
    () => editableLaneList.map((lane) => lane.cellTypes?.[runDesignCardIndex] ?? null),
    [runDesignCardIndex, editableLaneList]
  );

  const updateFromTableData = useCallback(
    (newTableData: (TRunDesignCellType | null)[]) => {
      setEditableLaneList((prev) => {
        const newLaneList = structuredClone(prev);
        newLaneList.forEach((lane, laneIndex) => {
          if (!newTableData[laneIndex]) {
            return lane;
          }
          if (!lane.cellTypes) {
            lane.cellTypes = [];
          }
          lane.cellTypes[runDesignCardIndex] = newTableData[laneIndex];
        });
        return newLaneList;
      });
    },
    [runDesignCardIndex, editableLaneList]
  );

  const handleNameChange = (laneIndex: number, newName: string, id: string) => {
    changeCellType(laneIndex, { cellIndex: { __typename: 'CellIndex', id, name: newName } });
  };

  const handleFunctionChange = (laneIndex: number, newFunction: CellFunction) => {
    changeCellType(laneIndex, { function: newFunction });
  };

  const handlePreLabelingChange = (laneIndex: number, newConsumable: Nullable<TRunDesignConsumable>) => {
    if (!newConsumable || !isReagentConsumable(newConsumable)) {
      changeCellType(laneIndex, { preLabeling: null });
      return;
    }

    changeCellType(laneIndex, {
      preLabeling: { __typename: 'ConsumableToUse', consumable: newConsumable as unknown as Consumable },
    });
  };

  const handlePreTreatmentChange = (laneIndex: number, newConsumable: Nullable<TRunDesignConsumable>) => {
    const oneOfAcceptedConsumables = isReagentConsumable(newConsumable) || isCompoundConsumable(newConsumable);
    if (!newConsumable || !oneOfAcceptedConsumables) {
      changeCellType(laneIndex, { preTreatment: null });
      return;
    }

    changeCellType(laneIndex, {
      preTreatment: { __typename: 'ConsumableToUse', consumable: newConsumable as unknown as Consumable },
    });
  };

  useEffect(() => {
    const laneList = currentEditFields.schema?.lanes ?? [];
    const initLaneList = LANE_LETTER_NAME_LIST.map((letter) => {
      const letterLane = laneList.find((lane) => lane.id === letter);
      if (letterLane) {
        return structuredClone(letterLane);
      }
      return { id: letter };
    });
    setEditableLaneList(initLaneList);
  }, [currentEditFields.schema?.lanes]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      isEditMode: true,
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateCellTypes(editableLaneList as Lane[]));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [editableLaneList, overrideCustomFooterConfig]);

  return (
    <RunDesignTable<(TRunDesignCellType | null)[]>
      className={cn('edit-cell-type__table')}
      tableData={tableData}
      setTableData={updateFromTableData}
      isEdit
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column>
            <span className={cn('edit-cell-type__header-title')}>Cell name</span>
          </RunDesignTable.Column>
          {showFunctionBlock && (
            <RunDesignTable.Column>
              <span className={cn('edit-cell-type__header-title')}>Function</span>
            </RunDesignTable.Column>
          )}
          {isSomeCellNameDefined && (
            <RunDesignTable.Column>
              <ReagentHeader className={cn('edit-cell-type__header-title')} withWaves={isPreTreatmentEX}>
                Pre-Treatment
              </ReagentHeader>
            </RunDesignTable.Column>
          )}

          {isSomeCellNameDefined && (
            <RunDesignTable.Column>
              <ReagentHeader className={cn('edit-cell-type__header-title')} withWaves={isPreLabelingEX}>
                Pre-Labeling
              </ReagentHeader>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {editableLaneList.map((lane, laneIndex) => {
        if (!('sample' in lane)) {
          return <RunDesignTable.Row key={lane.id} />;
        }

        const cellType = lane?.cellTypes?.[runDesignCardIndex];
        const preLabelingReagentData = isReagentConsumable(cellType?.preLabeling?.consumable)
          ? cellType?.preLabeling?.consumable ?? null
          : null;
        const preTreatmentConsumableData =
          isReagentConsumable(cellType?.preTreatment?.consumable) ||
          isCompoundConsumable(cellType?.preTreatment?.consumable)
            ? cellType?.preTreatment?.consumable ?? null
            : null;

        return (
          <RunDesignTable.Row key={lane.id}>
            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypeName
                cellType={lane.cellTypes?.[runDesignCardIndex]}
                laneIndex={laneIndex}
                onNameChange={handleNameChange}
              />
            </RunDesignTable.Column>
            {showFunctionBlock && (
              <RunDesignTable.Column className={cn('edit-cell-type__column')}>
                <EditCellTypeFunction
                  cellType={lane.cellTypes?.[runDesignCardIndex]}
                  laneIndex={laneIndex}
                  onChangeFunction={handleFunctionChange}
                />
              </RunDesignTable.Column>
            )}
            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypePreTreatment
                cellType={lane.cellTypes?.[runDesignCardIndex]}
                consumable={preTreatmentConsumableData}
                laneIndex={laneIndex}
                onChangeReagent={handlePreTreatmentChange}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column className={cn('edit-cell-type__column')}>
              <EditCellTypePreLabeling
                key={lane.id}
                cellType={lane.cellTypes?.[runDesignCardIndex]}
                reagent={preLabelingReagentData as Nullable<Reagent>}
                laneIndex={laneIndex}
                onChangeReagent={handlePreLabelingChange}
              />
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default EditCellType;
