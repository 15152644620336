import classnames from 'classnames/bind';
import ReagentWaves from '@/components/runDesign/ReagentComponent/ReagentWaves';

import styles from '../../EditFields.module.scss';

const cn = classnames.bind(styles);

const ReagentSelectedValue = ({ selectedValue }: { selectedValue: TBasicOption }) => (
  <ReagentWaves
    className={cn('field', 'edit-setting__waves')}
    detectionWavelength={selectedValue?.customData?.reagent?.detectionWavelength ?? 0}
    excitationWavelength={selectedValue?.customData?.reagent?.excitationWavelength ?? 0}
  />
);

export default ReagentSelectedValue;
