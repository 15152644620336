import { FC, MouseEventHandler, ReactElement, useMemo } from 'react';
import classNames from 'classnames/bind';

import icons from '@/components/common/icons';
import { isNumber } from '@/helpers';

import styles from './CounterModule.module.scss';

const cn = classNames.bind(styles);

type TCounterModuleProps = {
  className?: string;
  count: number;
  minCount?: number;
  description: string | ReactElement;
  onIncrease?: () => void;
  onDecrease?: () => void;
  isLocked?: boolean;
  showControls: boolean;
};

const CounterModule: FC<TCounterModuleProps> = ({
  className,
  count,
  description,
  onIncrease,
  onDecrease,
  isLocked = false,
  minCount,
  showControls,
}) => {
  const handleAddClick: MouseEventHandler<HTMLButtonElement> = () => {
    onIncrease?.();
  };
  const handleSubtractClick: MouseEventHandler<HTMLButtonElement> = () => {
    onDecrease?.();
  };

  const controlsRenderCondition = {
    lock: isLocked,
    default: !!onIncrease && !!onDecrease && !isLocked && showControls,
  };

  const isDecreaseBtnDisabled = useMemo(() => isNumber(minCount) && count === minCount, [minCount, count]);

  return (
    <div className={cn('counter-module', className)}>
      <div className={cn('counter-module__counter')}>{count}</div>
      {controlsRenderCondition.lock && <icons.LockIcon className={cn('counter-module__lock')} />}
      {controlsRenderCondition.default && (
        <div className={cn('counter-module__controls')}>
          <button
            className={cn('counter-module__button', 'counter-module__button_minus', {
              'counter-module__button_disabled': isDecreaseBtnDisabled,
            })}
            aria-label="decrease"
            onClick={handleSubtractClick}
            disabled={isDecreaseBtnDisabled}
          >
            <icons.MinusIcon />
          </button>
          <button
            className={cn('counter-module__button', 'counter-module__button_plus')}
            aria-label="increase"
            onClick={handleAddClick}
          >
            <icons.PlusIcon />
          </button>
        </div>
      )}
      <div className={cn('counter-module__description')}>{description}</div>
    </div>
  );
};

export default CounterModule;
