import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import { iconList } from '@/helpers/runDesigns/constants';
import IncubationBlock from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/IncubationBlock';
import WashBlock from '@/pages/experiment-run-design/DesignTimeline/components/SettingsModal/components/MainBlock/components/WashBlock';
import { Placement, TemperatureUnit } from '@/graphql/API';
import { ETimeInputUnit, TTimeInputUnit } from '@/components/common/TimeInput';
import { clamp } from '@/helpers';
import getTimeUnits from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getTimeUnits';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { isComponentWithIncubation, isComponentWithScan, isComponentWithWash } from '@/helpers/runDesigns/typeGuards';
import { EScanTotal, TScanTotal } from './types';
import SingleScanContent from './components/SingleScanContent';
import MultiScanContent from './components/MultiScanContent';
import SimultaneousScanContent from './components/SimultaneousScanContent';
import styles from './MainBlock.module.scss';

const cn = classNames.bind(styles);

type TMainBlockProps = {
  component: Nullable<TRunDesignComponent>;
  componentList: Nullable<TRunDesignComponent[]>;
  incubationDuration: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  incubationTemperature: {
    value: number;
    onChange: (value: number) => void;
  };
  incubationTemperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  incubationLocationOnDevice: {
    value: boolean;
    onChange: (value: boolean) => void;
  };
  washDuration: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  washTemperature: {
    value: number;
    onChange: (value: number) => void;
  };
  washTemperatureUnit: {
    value: TemperatureUnit;
    onChange: (value: TemperatureUnit) => void;
  };
  washNumberOfWashes: {
    value: number;
    onChange: (value: number) => void;
  };
  scanOrder: number;
  scanNumberOfScans: {
    value: number;
    onChange: (value: number) => void;
  };
  scanInterval: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
  scanFirstScanTime: {
    value: number;
    onChange: ({ value, unit }: { value: number; unit: TTimeInputUnit }) => void;
  };
};

const MainBlock: FC<TMainBlockProps> = ({
  component,
  componentList,
  incubationDuration,
  incubationTemperature,
  incubationTemperatureUnit,
  incubationLocationOnDevice,
  washDuration,
  washTemperature,
  washTemperatureUnit,
  washNumberOfWashes,
  scanOrder,
  scanNumberOfScans,
  scanInterval,
  scanFirstScanTime,
}) => {
  const hasIncubation = useMemo(() => isComponentWithIncubation(component), [component]);

  const hasWash = useMemo(() => isComponentWithWash(component), [component]);

  const hasScan = useMemo(() => isComponentWithScan(component), [component]);

  const incubationBlockProps = {
    durationHour: {
      value: getTimeUnits(incubationDuration.value).hours,
      onChange: (value: number) =>
        incubationDuration.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    durationMinute: {
      value: getTimeUnits(incubationDuration.value).minutes,
      onChange: (value: number) =>
        incubationDuration.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    temperature: incubationTemperature,
    temperatureUnit: incubationTemperatureUnit,
    location: incubationLocationOnDevice,
  };

  const washBlockProps = {
    durationHour: {
      value: getTimeUnits(washDuration.value).hours,
      onChange: (value: number) => washDuration.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    durationMinute: {
      value: getTimeUnits(washDuration.value).minutes,
      onChange: (value: number) => washDuration.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    temperature: washTemperature,
    temperatureUnit: washTemperatureUnit,
    washesTotal: washNumberOfWashes,
  };

  const singleScanProps = {
    scanOrder,
    scanTotal: {
      value: EScanTotal.single,
      onChange: (value: TScanTotal) => {
        scanNumberOfScans.onChange(value === EScanTotal.single ? 1 : 2);
      },
    },
  };

  const multiScanProps = {
    scanOrder,
    scanTotal: {
      value: EScanTotal.multi,
      onChange: (value: TScanTotal) => {
        scanNumberOfScans.onChange(value === EScanTotal.single ? 1 : 2);
      },
    },
    scanFirstScanTimeHour: {
      value: getTimeUnits(scanFirstScanTime.value).hours,
      onChange: (value: number) =>
        scanFirstScanTime.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    scanFirstScanTimeMinute: {
      value: getTimeUnits(scanFirstScanTime.value).minutes,
      onChange: (value: number) =>
        scanFirstScanTime.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    scanIntervalHour: {
      value: getTimeUnits(scanInterval.value).hours,
      onChange: (value: number) => scanInterval.onChange({ value: Math.min(value, 999), unit: ETimeInputUnit.hour }),
    },
    scanIntervalMinute: {
      value: getTimeUnits(scanInterval.value).minutes,
      onChange: (value: number) => scanInterval.onChange({ value: Math.min(value, 59), unit: ETimeInputUnit.minute }),
    },
    scanNumberOfScans: {
      value: scanNumberOfScans.value,
      onChange: (value: number) => scanNumberOfScans.onChange(clamp(2, value, 999)),
    },
  };

  const simultaneousScanProps = {
    componentList,
  };

  return (
    <div className={cn('main-block')}>
      <div className={cn('main-block__column')}>
        {hasIncubation && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Incubate
                {incubationLocationOnDevice.value && iconList.iconIncubation}
                {!incubationLocationOnDevice.value && iconList.iconIncubationOffDevice}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <IncubationBlock {...incubationBlockProps} />
            </div>
          </div>
        )}
        {hasWash && (
          <div className={cn('main-block__inner-column')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Wash
                {iconList.iconWash}
              </li>
            </ul>
            <div className={cn('main-block__content')}>
              <WashBlock {...washBlockProps} />
            </div>
          </div>
        )}
      </div>
      {hasScan && (
        <div className={cn('main-block__column')}>
          <div className={cn('main-block__inner-column', 'main-block__inner-column_scan')}>
            <ul className={cn('main-block__header-list')}>
              <li className={cn('main-block__header-elem')}>
                Scan
                {scanNumberOfScans.value === 1 && iconList.iconScanning}
                {component?.timing.placement === Placement.SIMULTANEOUS ||
                  (scanNumberOfScans.value !== 1 && iconList.iconScanningStacked)}
              </li>
            </ul>

            {component?.timing.placement !== Placement.SIMULTANEOUS && scanNumberOfScans.value === 1 && (
              <SingleScanContent {...singleScanProps} />
            )}
            {component?.timing.placement !== Placement.SIMULTANEOUS && scanNumberOfScans.value !== 1 && (
              <MultiScanContent {...multiScanProps} />
            )}
            {component?.timing.placement === Placement.SIMULTANEOUS && (
              <SimultaneousScanContent {...simultaneousScanProps} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MainBlock;
