import { createSelector } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';

import { LaneCagingSettings, OpticsSettingsItem } from '@/graphql/API';

import { arrToMapByKeys, isNumber, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';
import { assayIconList } from '@/helpers/runDesigns/constants';
import { isAssayComponent } from '@/helpers/runDesigns/typeGuards';

import type { RootState } from '@/store';

import { TMatrixSettingItem, TRunDesignCellType } from './types';

export const selectSource = (state: RootState) => state.experimentRunDesign.source;

export const selectData = (state: RootState) => state.experimentRunDesign.data;

export const selectInitialEditFields = (state: RootState) => state.experimentRunDesign.editFields.initial;

export const selectCurrentEditFields = (state: RootState) => state.experimentRunDesign.editFields.current;

export const selectComponentList = (state: RootState) =>
  state.experimentRunDesign.editFields.current?.schema?.components ?? [];

export const selectOpticalMatrixSettings = createSelector([selectComponentList], (componentList) => {
  const resData: TMatrixSettingItem[] = [];
  componentList.forEach((component) => {
    component.performedOnLanes.forEach((lane) => {
      lane.consumables?.forEach((usedConsumable) => {
        if (!usedConsumable) return;

        const id = `${lane.laneId}-${usedConsumable.consumable.id}`;
        const data: TMatrixSettingItem = {
          lane,
          consumable: usedConsumable.consumable,
          scanConfig: null,
          id,
          componentName: component.name,
          assayIcon: assayIconList[component.type],
          reagentId: usedConsumable.consumable.id,
        };

        if ('scanConfig' in component && component.scanConfig) {
          // todo: multiplex by scans
          const newId = `${component.scanConfig.scanIds[0]}-${lane.laneId}-${usedConsumable.consumable.id}`;
          data.id = newId;
          data.scanConfig = component.scanConfig;
        }

        resData.push(data);
      });
    });
  });
  return resData;
});

export const selectIncubationComponentList = createSelector([selectComponentList], (componentList) =>
  componentList.filter((component) => component.type === 'Incubation')
);

export const selectAssayComponentList = createSelector([selectComponentList], (componentList) =>
  componentList.filter((component) => isAssayComponent(component))
);

export const selectComponentById = (componentId: string) =>
  createSelector([selectComponentList], (componentList) =>
    componentList.find((component) => component.id === componentId)
  );

export const selectAssayComponentsCount = createSelector(
  [selectAssayComponentList],
  (assayComponentList) => assayComponentList.length
);

export const selectCurrentLanes = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.lanes ?? null;

export const selectCurrentLaneLetterMap = createSelector([selectCurrentLanes], (laneList) =>
  arrToMapByKeys(laneList ?? [], 'id')
);

export const selectCountOfCellTypes = (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  return lanes?.[0]?.cellTypes?.length ?? 0;
};

export const selectIsEditFieldsHaveChanges = createSelector(
  [selectInitialEditFields, selectCurrentEditFields],
  (initialEditFields, currentEditFields) => !isEqual(initialEditFields, currentEditFields)
);

export const selectCurrentLaneById = (laneId: string) => (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  const lane = lanes?.find((el) => laneId === el.id);
  return lane;
};

export const selectSomeRunDesignCardIsEdit = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexEdit >= 0;

export const selectSomeRunDesignCardIsExpand = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexExpand >= 0;

export const selectRunDesignCardIndexEdit = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexEdit;
export const selectRunDesignCardIndexExpand = (state: RootState) =>
  state.experimentRunDesign.addData.runDesignCardIndexExpand;
export const selectIsAdvancedMode = (state: RootState) => state.experimentRunDesign.addData.isAdvancedMode;

export const selectIsAllLanesHasPreLabelingEX = (state: RootState) => {
  const lanes = selectCurrentLanes(state);
  return lanes?.every((lane) => lane.cellTypes?.some((cellType) => !!cellType?.preLabeling?.consumable)) ?? false;
};

export const selectIsPreLabelingEX = (cellTypeIndex?: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    if (isNumber(cellTypeIndex)) {
      // select is PreLabeling in specific cellType
      return (
        lanes?.some(
          (lane) =>
            !!lane.cellTypes?.find((cellType, index) => index === cellTypeIndex && !!cellType?.preLabeling?.consumable)
        ) ?? false
      );
    }
    return lanes?.some((lane) => lane.cellTypes?.some((cellType) => !!cellType?.preLabeling?.consumable)) ?? false;
  });

export const selectIsPreTreatmentEX = (cellTypeIndex?: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    if (isNumber(cellTypeIndex)) {
      // select is PreTreatment in specific cellType
      return (
        lanes?.some(
          (lane) =>
            !!lane.cellTypes?.find((cellType, index) => index === cellTypeIndex && !!cellType?.preTreatment?.consumable)
        ) ?? false
      );
    }
    return lanes?.some((lane) => lane.cellTypes?.some((cellType) => !!cellType?.preTreatment?.consumable)) ?? false;
  });

export const selectCellTypeListByIndex = (index: number) =>
  createSelector([selectCurrentLanes], (lanes) => {
    const lanesMap = arrToMapByKeys(lanes ?? [], 'id');
    return LANE_LETTER_NAME_LIST.map((laneLetter) => lanesMap[laneLetter]?.cellTypes?.[index] ?? null);
  });

export const selectCagingSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.cagingSettings;

export const selectLanesCagingSettings = createSelector(
  [selectCagingSettings],
  (cagingSettings) => cagingSettings?.perLane?.filter((el) => isDefined(el)) as LaneCagingSettings[] | undefined
);

export const selectGlobalCagingSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.cagingSettings?.global;

export const selectAllSelectedCellTypes = createSelector([selectCurrentLanes], (lanes) => {
  const listOfCellTypes: TRunDesignCellType[] = [];
  lanes?.forEach((lane) => {
    lane.cellTypes?.forEach((cellType) => {
      if (!cellType) {
        return;
      }
      listOfCellTypes.push(cellType);
    });
  });

  return listOfCellTypes;
});

export const selectGlobalOpticalSettings = (state: RootState) =>
  state.experimentRunDesign.editFields.current.schema?.opticsSettings?.global;

export const selectLanesOpticalSettings = createSelector([], () => [] as Array<OpticsSettingsItem>);

export const selectIsGlobalSettingsView = (state: RootState) => state.experimentRunDesign.addData.isGlobalSettingsView;

export const selectIsAutoGenerateSampleNames = (state: RootState) =>
  state.experimentRunDesign.addData.isAutoGenerateSampleNames;

export const isSomeSamplesWithoutName = createSelector([selectCurrentLanes], (laneList) => {
  if (!laneList?.length) return false;
  return laneList.some((lane) => !lane.sample?.trim());
});

export const selectIsSampleNamesGenerated = (state: RootState) =>
  state.experimentRunDesign.addData.isSampleNamesGenerated;
