import { FC, useMemo } from 'react';
import classNames from 'classnames/bind';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import { isDefined } from '@/helpers/typeGuards';
import { isComponentWithScan } from '@/helpers/runDesigns/typeGuards';
import Table from './Table';
import styles from '../MainBlock.module.scss';

const cn = classNames.bind(styles);

type TSimultaneousScanContentProps = {
  componentList: Nullable<TRunDesignComponent[]>;
};

const SimultaneousScanContent: FC<TSimultaneousScanContentProps> = ({ componentList }) => {
  const scanCount = useMemo(
    () =>
      componentList?.reduce<number>((acc, component) => {
        if (isComponentWithScan(component) && isDefined(component.scanConfig)) {
          return acc + component.scanConfig.numberOfScans;
        }

        return acc + 1;
      }, 0) ?? 0,
    [componentList]
  );

  const scanInfo = useMemo(() => {
    let currentOrder = 0;
    return componentList?.reduce<{ count: string; placement: string }[]>((acc, component) => {
      const numberOfScans = (isComponentWithScan(component) && component.scanConfig?.numberOfScans) || 1;
      const frequency = (isComponentWithScan(component) && component.scanConfig?.scanEvery) || 0;
      const frequencyHour = Math.floor(frequency / 60);
      const frequencyMinute = frequency % 60;

      const count =
        numberOfScans === 1 ? String(currentOrder + 1) : `${currentOrder + 1}-${currentOrder + numberOfScans}`;

      const placement =
        numberOfScans === 1
          ? `After ${component.name}`
          : `After ${component.name} (${numberOfScans} scans, ${frequencyHour}h ${frequencyMinute}m frequency)`;

      currentOrder += numberOfScans;
      acc.push({
        count,
        placement,
      });
      return acc;
    }, []);
  }, [componentList]);

  return (
    <div className={cn('main-block__content')}>
      <div className={cn('main-block__sub-column')}>
        <div className={cn('main-block__block')}>
          <div className={cn('main-block__group')}>
            <div className={cn('main-block__block-title')}>scans total</div>
            <span className={cn('main-block__block-text')}>{scanCount}</span>
          </div>
          <div className={cn('main-block__group', 'main-block__group_baseline-scan')}>
            <div className={cn('main-block__block-title')}>Baseline scan</div>
            <Select
              options={[{ value: 'Scan1', label: 'Scan1' }]}
              value="Scan1"
              onChange={() => null}
              theme={themeOptions.light}
              placeholder="Select scan total..."
              className={cn('main-block__select')}
              controlClassName={cn('main-block__select-control')}
              menuClassName={cn('main-block__select-menu')}
              isPortalable={false}
            />
          </div>
        </div>
        <Table
          heading={['scan number', 'scan type']}
          content={
            scanInfo?.map(({ count, placement }) => [
              <span className={cn('main-block__block-text')} key={`${placement}_key`}>
                {count}
              </span>,
              <span className={cn('main-block__scan-type')} key={`${placement}_value`}>
                {placement}
              </span>,
            ]) ?? []
          }
        />
      </div>
    </div>
  );
};

export default SimultaneousScanContent;
