import { isComponentWithIncubation, isComponentWithScan, isComponentWithWash } from '@/helpers/runDesigns/typeGuards';

import { Placement } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

export const getScanOrdersMap = (componentList: TRunDesignComponent[]) => {
  let currentOrder = 1;
  const map: Record<string, number> = {};
  componentList.forEach((component) => {
    if (isComponentWithScan(component) && component.scanConfig) {
      const numberOfScans = component.scanConfig.numberOfScans ?? 1;
      map[component.id] = currentOrder;
      currentOrder += numberOfScans;
    }
  });
  return map;
};

export const getScanLabelsMap = (componentList: TRunDesignComponent[]) => {
  let currentOrder = 0;
  const map: Record<string, string> = {};
  componentList.forEach((component) => {
    if (isComponentWithScan(component)) {
      const numberOfScans = component.scanConfig?.numberOfScans ?? 1;
      map[component.id] =
        numberOfScans === 1 ? `Scan ${currentOrder + 1}` : `Scans ${currentOrder + 1}-${currentOrder + numberOfScans}`;
      currentOrder += numberOfScans;
    }
  });
  return map;
};

export const getComponentPosition = (
  componentList: TRunDesignComponent[],
  component: TRunDesignComponent,
  row: number
) => {
  let column = 0; // invalid css value of "grid-column-start" property to place items in "auto" columns
  let columnEnd: number | undefined;
  const relativeToIndex = componentList.findIndex(
    (firstRowComponent) => firstRowComponent.id === component.timing.relativeTo
  );

  const placementActions = {
    [Placement.START]: () => {
      column = 1;
    },
    [Placement.END]: () => {
      column = componentList.length;
    },
    [Placement.SIMULTANEOUS]: () => {
      if (relativeToIndex < 0) {
        return;
      }
      column = relativeToIndex + 1;
      columnEnd = componentList.length + 1;
    },
  };

  if (component.timing.placement in placementActions) {
    placementActions[component.timing.placement as keyof typeof placementActions]();
  }

  return {
    row,
    column,
    columnEnd,
  };
};

export const getComponentDuration = (component: TRunDesignComponent) => {
  let duration = component.duration ?? 0;
  if (isComponentWithIncubation(component)) {
    duration += component.incubation?.duration ?? 0;
  }
  if (isComponentWithWash(component)) {
    duration += component.wash?.duration ?? 0;
  }
  return duration;
};
