import { createContext, ReactNode } from 'react';
import type { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';

export const RunDesignContext = createContext<{
  footerConfig?: TDefaultPageControlsConfig;
  overrideCustomFooterConfig?: (overrideValue: Partial<TDefaultPageControlsConfig>) => void;
  clearCustomFooterConfig?: () => void;
  addCustomControls?: (controls: ReactNode) => void;
  removeCustomControls?: () => void;
  setTitle?: (title: ReactNode) => void;
  saveAndContinueMethod?: () => void;
  setSaveAndContinueMethod?: (method: () => void) => void;
  cancelMethod?: () => void;
  setCancelMethod?: (method: () => void) => void;
  addCustomHeaderContent?: (node: ReactNode) => void;
  clearCustomHeaderContent?: () => void;
  handleUpdateRunDesign?: (successCallback: () => void) => void;
  isSavingRunDesign?: boolean;
}>({});
