import { FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { formatDate } from '@/helpers';
import RadialLoader from '@/components/common/RadialLoader';
import Button from '@/components/common/Button';
import Slash from './iconList/Slash';
import Arrow from './iconList/Arrow';
import styles from './HomeCard.module.scss';
import { HomeCardLayout, isSeparatedCardData, THomeCardLayout, TSeparatedCardData, TUnionCardData } from '../../types';

const cn = classNames.bind(styles);

type HomeCardProps = {
  title: ReactNode;
  layout?: THomeCardLayout;
  image: {
    url: string;
    alt?: string;
  };
  data: TSeparatedCardData | TUnionCardData;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const HomeCard: FC<HomeCardProps> = ({ title, layout = HomeCardLayout.separated, image, data, onClick }) => (
  <button
    className={cn('card', {
      _separated: layout === HomeCardLayout.separated,
      _union: layout === HomeCardLayout.union,
    })}
    onClick={onClick}
  >
    <div className={cn('card__wrap')}>
      <div className={cn('card__bg-image-wrap')}>
        <img className={cn('card__bg-image')} src={image.url} alt={image.alt} />
      </div>
      <div className={cn('card__heading')}>
        <Slash className={cn('card__heading-slash')} />
        {title}
        <br />
        <Arrow className={cn('card__heading-arrow')} />
      </div>

      {layout === HomeCardLayout.separated && isSeparatedCardData(data) && (
        <>
          <div className={cn('card__data', 'card__counter-wrap', 'card__data_with-right-separator')}>
            <div className={cn('card__counter')}>{data.total}</div>
          </div>
          <div className={cn('card__data', 'card__info')}>
            <div className={cn('card__status')}>{data.status}</div>
            {!!data.date && (
              <div className={cn('card__last')}>
                Last:
                <br />
                {formatDate(data.date, 'MMM d, h:mm a')}
              </div>
            )}
          </div>
          <div className={cn('card__counter-with-comment', 'card__data', 'card__row', 'card__data_with-top-separator')}>
            <div className={cn('card__mini-counter-wrap')}>
              <div className={cn('card__mini-counter')}>{data.current.count}</div>
              {data.current.isInProcess && <RadialLoader className={cn('card__radial-loader')} />}
            </div>
            <div className={cn('card__comment')}>{data.current.comment}</div>
          </div>
        </>
      )}

      {layout === HomeCardLayout.union &&
        !isSeparatedCardData(data) &&
        data.counterList.map((counter, index) => (
          <div className={cn('card__data', 'card__data_with-top-separator')} key={index}>
            <div className={cn('card__mini-counter-wrap')}>
              <div className={cn('card__mini-counter')}>{counter.count}</div>
              {counter.isInProcess && <RadialLoader className={cn('card__radial-loader')} />}
            </div>
            <div className={cn('card__comment')}>{counter.comment}</div>
          </div>
        ))}

      <div className={cn('card__cta-wrap', 'card__row')}>
        <Button color={layout === HomeCardLayout.separated ? 'yellow' : 'dark'} className={cn('card__cta')}>
          {data.ctaText}
        </Button>
      </div>
    </div>
  </button>
);

export default HomeCard;
