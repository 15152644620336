import { FC, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { MIN_LANES_COUNT } from '@/helpers';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import CounterModule from './CounterModule';

import styles from '../RunDetails.module.scss';

const cn = classnames.bind(styles);

type TLanesTotalProps = {
  isChangeAllowed: boolean;
};

const LanesTotal: FC<TLanesTotalProps> = ({ isChangeAllowed }) => {
  const appDispatch = useAppDispatch();

  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const lanesCount = useMemo(() => currentEditFields.schema?.lanes?.length ?? 0, [currentEditFields.schema?.lanes]);

  const handleLaneCountIncrease = () => {
    appDispatch(experimentRunDesignActions.addNewLane());
  };

  const handleLaneCountDecrease = () => {
    appDispatch(experimentRunDesignActions.removeLane());
  };

  return (
    <CounterModule
      className={cn('run-details__module')}
      count={lanesCount}
      onIncrease={handleLaneCountIncrease}
      onDecrease={handleLaneCountDecrease}
      showControls={isChangeAllowed}
      description="Lanes total"
      isLocked={someRunDesignCardIsEdit}
      minCount={MIN_LANES_COUNT}
    />
  );
};

export default memo(LanesTotal);
