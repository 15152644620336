import { FC, MutableRefObject, useCallback, useMemo, useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignCard from '@/components/runDesign/RunDesignCard';
import Button from '@/components/common/Button';

import EditSettings from '../EditSettings';
import ReviewSettings from '../ReviewSettings';

import styles from './SettingsCard.module.scss';

const cn = classnames.bind(styles);

type TSettingsCard = {
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
  openSettingsInfoModal: (isEditMode?: boolean) => void;
};

const SettingsCard: FC<TSettingsCard> = ({ innerRef, openSettingsInfoModal }) => {
  const appDispatch = useAppDispatch();
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const runDesignCardIndexExpand = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexExpand);

  const [isViewMoreActive, setIsViewMoreActive] = useState(false);

  const handleViewMoreClick = () => setIsViewMoreActive((prev) => !prev);

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
  }, [isEditMode]);

  const settingsClickHandler = useMemo(
    () => (!isEditMode ? handleSettingClick : undefined),
    [handleSettingClick, isEditMode]
  );

  const handleExpandMode = useCallback(
    (index: number) => {
      const newIndex = runDesignCardIndexExpand === index ? -1 : index;
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexExpand(newIndex));
    },
    [runDesignCardIndexExpand]
  );

  const changeExpandMode = useCallback(() => {
    handleExpandMode?.(0);
  }, [handleExpandMode]);

  const expandModeClickHandler = useMemo(
    () => (isEditMode ? changeExpandMode : undefined),
    [changeExpandMode, isEditMode]
  );

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  return (
    <RunDesignCard cardRef={innerRef} className={cn('settings-card')}>
      <RunDesignCard.Header
        onSettingClick={settingsClickHandler}
        onExpandClick={expandModeClickHandler}
        className={cn('header')}
        contentClassName={cn('header__content')}
        actionsClassName={cn('header__actions')}
        withDivider={false}
        isEditMode={isEditMode}
      >
        <div
          className={cn('header-sections', {
            'header-sections_wide': isEditMode && isViewMoreActive,
            'header-sections_edit-mode': isEditMode,
          })}
        >
          <div className={cn('header-sections__container')}>
            <span className={cn('header-sections__title')}>CCE parameters</span>
          </div>
          <div className={cn('header-sections__container')}>
            {isEditMode && (
              <div className={cn('header-sections__btn-container')}>
                <Button
                  onClick={handleViewMoreClick}
                  isFitContent
                  isBgLight
                  type="button"
                  color="dark"
                  className={cn('header-sections__view-more')}
                >
                  {isViewMoreActive ? 'Hide advanced' : 'View advanced'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </RunDesignCard.Header>
      <RunDesignCard.Content className={cn('content', 'content_no-overflow-scroll')} onClick={handleCardContentClick}>
        {!isEditMode && <ReviewSettings cardRef={innerRef} openEditMode={handleSettingClick} />}
        {isEditMode && (
          <EditSettings
            openSettingsInfoModal={openSettingsInfoModal}
            cardRef={innerRef}
            isViewMoreActive={isViewMoreActive}
          />
        )}
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default SettingsCard;
