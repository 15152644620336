import { CSSProperties, FC, MouseEventHandler } from 'react';
import classNames from 'classnames/bind';

import styles from './SchemaComponent.module.scss';

const cn = classNames.bind(styles);

type TSchemaComponentProps = {
  className?: string;
  label: string;
  scanLabel: string;
  isCurrent: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
};

const SchemaComponent: FC<TSchemaComponentProps> = ({
  className,
  label,
  scanLabel,
  isCurrent = false,
  onClick,
  position,
}) => (
  <button
    className={cn(
      'schema-component',
      { 'schema-component_current': isCurrent, 'schema-component_no-scan': !scanLabel },
      className
    )}
    onClick={onClick}
    style={{ '--row': position.row, '--column': position.column, '--column-end': position.columnEnd } as CSSProperties}
  >
    <div className={cn('schema-component__step', 'schema-component__incubation')}>{label}</div>
    {scanLabel && <div className={cn('schema-component__step', 'schema-component__scan')}>{scanLabel}</div>}
  </button>
);

export default SchemaComponent;
