import { OptionProps } from 'react-select';
import classnames from 'classnames/bind';

import ReagentComponent from '.';

import styles from './Reagent.module.scss';

const cn = classnames.bind(styles);

type TReagentOption = OptionProps<TBasicOption> & { reagentClassName?: string };

const ReagentOption = ({ data, innerProps, innerRef, reagentClassName }: TReagentOption) => (
  <article ref={innerRef} {...innerProps} className={cn('reagent-option__wrapper')}>
    <ReagentComponent className={cn('reagent-option__item', reagentClassName)} consumable={data.customData as any} />
  </article>
);

export default ReagentOption;
