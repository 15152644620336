import {
  CellCaging,
  CellKilling,
  Compound,
  CytokineSecretion,
  Incubation,
  mRNA,
  Reagent,
  ScanComp,
  SelectiveRetention,
  Stain,
  SurfaceReceptor,
  SurfaceReceptorCytokineSecretion,
  Treatment,
} from '@/graphql/API';

import { TRunDesignComponent, TRunDesignConsumable } from '@/store/slices/experimentRunDesign/types';

export const isIncubationComponent = (component?: Nullable<TRunDesignComponent>): component is Incubation =>
  (component && component.__typename === 'Incubation') ?? false;

export const isScanComponent = (component?: Nullable<TRunDesignComponent>): component is ScanComp =>
  (component && component.__typename === 'ScanComp') ?? false;

export const isComponentWithWash = (
  component?: Nullable<TRunDesignComponent>
): component is SurfaceReceptor | SurfaceReceptorCytokineSecretion | CellKilling | CytokineSecretion | Treatment =>
  (component &&
    ['SurfaceReceptor', 'SurfaceReceptorCytokineSecretion', 'CellKilling', 'CytokineSecretion', 'Treatment'].includes(
      component.__typename
    )) ??
  false;

export const isComponentWithIncubation = (
  component?: Nullable<TRunDesignComponent>
): component is
  | SurfaceReceptor
  | SurfaceReceptorCytokineSecretion
  | CellKilling
  | CytokineSecretion
  | Incubation
  | Treatment =>
  (component &&
    [
      'SurfaceReceptor',
      'SurfaceReceptorCytokineSecretion',
      'CellKilling',
      'CytokineSecretion',
      'Incubation',
      'Treatment',
    ].includes(component.__typename)) ??
  false;

export const isComponentWithScan = (
  component?: Nullable<TRunDesignComponent>
): component is
  | CellCaging
  | CellKilling
  | CytokineSecretion
  | Incubation
  | SelectiveRetention
  | SurfaceReceptor
  | SurfaceReceptorCytokineSecretion
  | Treatment =>
  (component &&
    [
      'CellCaging',
      'CellKilling',
      'CytokineSecretion',
      'Incubation',
      'SelectiveRetention',
      'SurfaceReceptor',
      'SurfaceReceptorCytokineSecretion',
      'Treatment',
    ].includes(component.__typename)) ??
  false;

export const isReagentConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Reagent =>
  (consumable && ['Reagent'].includes(consumable.__typename)) ?? false;

export const isCompoundConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Compound =>
  (consumable && ['Compound'].includes(consumable.__typename)) ?? false;

export const isStainConsumable = (consumable?: Nullable<TRunDesignConsumable>): consumable is Stain =>
  (consumable && ['Stain'].includes(consumable.__typename)) ?? false;

export const isAssayComponent = (
  component?: Nullable<TRunDesignComponent>
): component is SurfaceReceptor | SurfaceReceptorCytokineSecretion | CellKilling | CytokineSecretion | mRNA =>
  (component &&
    ['SurfaceReceptor', 'SurfaceReceptorCytokineSecretion', 'CellKilling', 'CytokineSecretion', 'mRNA'].includes(
      component.__typename
    )) ??
  false;
