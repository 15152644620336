import { FC, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import Title from '@/components/runDesign/Title';

import { RunDesignContext } from '../context';
import SettingsCard from './components/SettingsCard';
import CustomPageHeader from './components/CustomPageHeader';

const OpticalSettings: FC = () => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig, clearCustomFooterConfig, addCustomHeaderContent, clearCustomHeaderContent } =
    useContext(RunDesignContext);

  const runDesignCardIndexEdit = useSelector(experimentRunDesignSelectors.selectRunDesignCardIndexEdit);

  const cardRef = useRef(null);

  useEffect(() => {
    if (!overrideCustomFooterConfig || !clearCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      refCheck: cardRef,
      isEditMode: runDesignCardIndexEdit >= 0,
      cancel: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
          appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(true));
        },
      },
    });
    return () => {
      clearCustomFooterConfig();
    };
  }, [overrideCustomFooterConfig, clearCustomFooterConfig, runDesignCardIndexEdit]);

  useEffect(() => {
    if (!addCustomHeaderContent || !clearCustomHeaderContent) {
      return;
    }

    if (runDesignCardIndexEdit >= 0) {
      addCustomHeaderContent(<CustomPageHeader />);
    } else {
      clearCustomHeaderContent();
    }

    return () => {
      clearCustomHeaderContent();
    };
  }, [addCustomHeaderContent, clearCustomHeaderContent, runDesignCardIndexEdit]);

  return (
    <>
      <Title>Review optical settings</Title>
      <SettingsCard innerRef={cardRef} />
    </>
  );
};

export default OpticalSettings;
