import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { Reagent, Stain } from '@/graphql/API';

import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import {
  experimentRunDesignSelectors,
  TRunDesignConsumable,
  TRunDesignConsumableToUse,
} from '@/store/slices/experimentRunDesign';
import ReagentComponent from '@/components/runDesign/ReagentComponent';

import ReagentOption from '@/components/runDesign/ReagentComponent/ReagentOption';
import PreSelectedReagent from '@/components/runDesign/ReagentComponent/PreSelectedReagent';
import AddToTableButton from '@/components/runDesign/AddToTableButton';
import useAsyncSelectAnnotation from '@/pages/experiment-run-design/SampleInformation/hooks/useAsynSelectAnnotation';
import SampleInformationAsyncSelect from '@/pages/experiment-run-design/SampleInformation/components/EditCellType/components/SampleInformationAsyncSelect';

import styles from '@/components/runDesign/ReagentComponent/Reagent.module.scss';
import { isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';
import { useSelector } from 'react-redux';

const cn = classnames.bind(styles);

export type TReagentCellClick = (
  consumableToUse: Nullable<TRunDesignConsumableToUse>,
  reagentPos: { laneId: string; componentId: string; reagentIndex: number }
) => void;

export type TReagentCell = {
  usedReagent?: Nullable<TRunDesignConsumableToUse>;
  preselectedUsedReagent?: Nullable<TRunDesignConsumableToUse>;
  usedReagentIndex: number;
  onPreselectedClick: TReagentCellClick;
  onAddNew: TReagentCellClick;
  laneId: string;
  componentId: string;
  isEdit?: boolean;
  withWaves?: boolean;
};

const EComponentType = {
  select: 'select',
  addNew: 'addNew',
  reagent: 'reagent',
  preselected: 'preselected',
} as const;

type TComponentType = keyof typeof EComponentType;

const ReagentCell: FC<TReagentCell> = ({
  usedReagent,
  preselectedUsedReagent,
  usedReagentIndex,
  isEdit,
  laneId,
  componentId,
  onAddNew,
  onPreselectedClick,
  withWaves,
}) => {
  const [componentType, setComponentType] = useState<TComponentType>(EComponentType.addNew);
  const component = useSelector(experimentRunDesignSelectors.selectComponentById(componentId));

  const preselectedReagentConsumable = useMemo(
    () =>
      isReagentConsumable(preselectedUsedReagent?.consumable) || isStainConsumable(preselectedUsedReagent?.consumable)
        ? preselectedUsedReagent?.consumable
        : null,
    [preselectedUsedReagent?.consumable]
  );

  const reagentConsumable = useMemo(() => {
    const isValidConsumable =
      isReagentConsumable(usedReagent?.consumable) || isStainConsumable(preselectedUsedReagent?.consumable);
    return (usedReagent?.consumable && isValidConsumable ? usedReagent.consumable : null) as Nullable<Reagent | Stain>;
  }, [usedReagent?.consumable]);

  const onSelectChange = useCallback(
    (selectedReagent: Nullable<Reagent | Stain>) => {
      const newReagent: Nullable<TRunDesignConsumableToUse> = selectedReagent
        ? {
            ...usedReagent,
            consumable:
              selectedReagent ?? ({ id: '', name: '', __typename: 'Reagent', type: 'Reagent' } as TRunDesignConsumable),
            __typename: 'ConsumableToUse',
          }
        : null;
      onAddNew(newReagent, { componentId, laneId, reagentIndex: usedReagentIndex });
    },
    [onAddNew, laneId, componentId, usedReagentIndex]
  );

  const {
    selectRef,
    handleSetEditMode,
    handleSelectedNameChange,
    loadOptions,
    noOptionsMessage,
    selectedValue,
    isEditSelectMode,
  } = useAsyncSelectAnnotation<Reagent | Stain>({
    annotation: reagentConsumable,
    onChange: onSelectChange,
    annotationType: component?.__typename === 'CellKilling' ? EAnnotationType.stain : EAnnotationType.reagent,
    annotationLabelKey: 'name',
    annotationValueKey: 'id',
  });

  const handlePreselectedClick = useCallback(() => {
    if (!preselectedUsedReagent?.consumable?.id) {
      return;
    }
    onPreselectedClick(preselectedUsedReagent, { laneId, componentId, reagentIndex: usedReagentIndex });
  }, [preselectedUsedReagent?.consumable?.id, usedReagentIndex, laneId, componentId]);

  const handleReagentClick = useCallback(() => {
    handleSetEditMode(true);
    setComponentType(EComponentType.select);
  }, [usedReagent?.consumable.id]);

  const handleNewClick = useCallback(() => {
    setComponentType(EComponentType.select);
    handleSetEditMode(true);
  }, [handleSetEditMode]);

  const getComponentType = useCallback((): TComponentType => {
    if (usedReagent?.consumable.id) {
      return EComponentType.reagent;
    }

    if (preselectedUsedReagent?.consumable.id) {
      return EComponentType.preselected;
    }

    return EComponentType.addNew;
  }, [usedReagent?.consumable.id, preselectedUsedReagent?.consumable.id]);

  const onBlur = useCallback(() => {
    const updatedComponentType = getComponentType();
    setComponentType(updatedComponentType);
  }, [getComponentType]);

  useEffect(() => {
    handleSetEditMode(false);
  }, [usedReagent?.consumable]);

  useEffect(() => {
    if (isEditSelectMode || componentType === EComponentType.select) {
      selectRef.current?.focus();
    }
  }, [isEditSelectMode, componentType]);

  useEffect(() => {
    const updatedComponentType = getComponentType();

    setComponentType(updatedComponentType);

    return () => {
      const newUpdatedComponentType = getComponentType();

      setComponentType(newUpdatedComponentType);
    };
  }, [preselectedUsedReagent?.consumable.id, usedReagent?.consumable.id, isEdit, usedReagentIndex]);

  return (
    <div className={cn('reagent-container')}>
      {reagentConsumable && componentType === EComponentType.reagent && usedReagent?.consumable.id && (
        <ReagentComponent
          onClick={isEdit ? handleReagentClick : null}
          consumable={reagentConsumable}
          withWaves={withWaves}
        />
      )}
      {componentType === EComponentType.preselected && isEdit && preselectedReagentConsumable?.id && (
        <PreSelectedReagent
          onClick={handlePreselectedClick}
          reagent={preselectedReagentConsumable}
          onPlusClick={handleNewClick}
        />
      )}
      {componentType === EComponentType.addNew && isEdit && (
        <AddToTableButton onClick={handleNewClick} text="Add reagent" />
      )}
      {componentType === EComponentType.select && (
        <SampleInformationAsyncSelect
          selectRef={selectRef}
          onBlur={onBlur}
          openMenuOnFocus
          value={selectedValue}
          noOptionsMessage={noOptionsMessage}
          onChange={handleSelectedNameChange}
          loadOptions={loadOptions}
          components={{ Option: ReagentOption }}
          menuClassName={cn('select-menu')}
          isClearable
        />
      )}
      {componentType === EComponentType.preselected && !isEdit && preselectedUsedReagent?.consumable.id && <div />}
      {componentType === EComponentType.addNew && !isEdit && <div />}
    </div>
  );
};

export default ReagentCell;
