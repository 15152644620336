import { FC, SetStateAction, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';
import { removeDuplicates } from '@/helpers';
import { CAGING_SETTINGS_WAVES_DEFAULT_REAGENT } from '@/pages/experiment-run-design/CagingSettings/constants';

import { experimentRunDesignSelectors, TRunDesignCellType } from '@/store/slices/experimentRunDesign';

import Select from '@/components/common/Select';

import ReagentSelectedValue from './ReagentSelectedValue';

import styles from '../../EditFields.module.scss';
import ReagentOption from './ReagentOption';
import { TLaneCagingSettings } from '../../../../types';

const cn = classnames.bind(styles);

type TReagentSelector = {
  laneCagingSettings: TLaneCagingSettings;
  setTableData: (data: SetStateAction<Nullable<TLaneCagingSettings>[]>) => void;
  laneIndex: number;
  type: 'cellToCage' | 'cellToSubtract';
  className?: string;
};

const ReagentSelector: FC<TReagentSelector> = ({
  laneCagingSettings,
  setTableData,
  laneIndex,
  type,
  className,
}: TReagentSelector) => {
  const allSelectedCellTypes = useSelector(experimentRunDesignSelectors.selectAllSelectedCellTypes);

  const selectedReagent: TBasicOption = useMemo(
    () =>
      laneCagingSettings?.[type]?.preLabeling?.consumable
        ? {
            label: laneCagingSettings?.[type]?.preLabeling?.consumable.name ?? '',
            value: laneCagingSettings?.[type]?.preLabeling?.consumable.id ?? '',
            customData: {
              type: 'Pre-labeled',
              reagent: laneCagingSettings?.[type]?.preLabeling?.consumable,
            },
          }
        : CAGING_SETTINGS_WAVES_DEFAULT_REAGENT,
    [laneCagingSettings?.[type]?.preLabeling?.consumable]
  );

  const optionList = useMemo(() => {
    const reagentConsumablesFromLaneCellTypes: TBasicOption[] = [CAGING_SETTINGS_WAVES_DEFAULT_REAGENT];

    allSelectedCellTypes.forEach((cellType) => {
      if (isReagentConsumable(cellType?.preLabeling?.consumable)) {
        const data = {
          label: cellType.preLabeling.consumable.name,
          value: cellType.preLabeling.consumable.id,
          customData: {
            type: 'Pre-labeled',
            reagent: cellType.preLabeling.consumable,
          },
        };
        reagentConsumablesFromLaneCellTypes.push(data);
      }
    });

    return removeDuplicates(reagentConsumablesFromLaneCellTypes, 'value');
  }, [allSelectedCellTypes]);

  const handleChangeCellsToCageReagent = useCallback(
    (value: string) => {
      const currentOption = optionList.find((option) => value === option.value);
      setTableData((prev) => {
        const newSettings = structuredClone(prev);
        const laneData = newSettings[laneIndex];

        if (!currentOption?.customData?.reagent || !laneData?.[type]) {
          return prev;
        }

        const dataSource = type === 'cellToCage' ? laneData?.cellToCage : laneData?.cellToSubtract;

        if (!dataSource?.preLabeling?.consumable) {
          laneData[type] = {
            ...laneData[type],
            preLabeling: {
              __typename: 'ConsumableToUse',
              consumable: { ...currentOption.customData.reagent },
            },
          } as TRunDesignCellType;
        } else {
          dataSource.preLabeling.consumable = { ...currentOption.customData.reagent };
        }

        return newSettings;
      });
    },
    [optionList, laneCagingSettings]
  );

  return (
    <Select
      value={selectedReagent}
      customComponents={{
        Option: ReagentOption as any,
        SingleValue: () => (<ReagentSelectedValue selectedValue={selectedReagent} />) as any,
      }}
      onChange={handleChangeCellsToCageReagent}
      placeholder=""
      options={optionList}
      theme={themeOptions.light}
      valueContainerClassName={cn('select__waves-value-container')}
      className={cn('select', className)}
      controlClassName={cn('select__control')}
      menuClassName={cn('select__menu', 'select__menu_waves')}
      optionClassName={cn('select__option')}
      menuListClassName={cn('select__waves-menu-list')}
      disabled={!laneCagingSettings?.[type]}
    />
  );
};

export default ReagentSelector;
