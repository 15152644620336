import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { getWaveUiInfo } from '@/helpers/waveLengths';
import { DecimalRegex } from '@/helpers/validator';
import { MDASH } from '@/helpers';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors, TMatrixSettingItem } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ReagentComponent from '@/components/runDesign/ReagentComponent';
import NumberInput from '@/components/common/NumberInput';
import ReagentCell from '@/components/runDesign/ReagentComponent/ReagentCell';
import NoDataFound from '@/components/common/NoDataFound';

import styles from './MatrixView.module.scss';

const cn = classnames.bind(styles);

const MatrixView: FC = () => {
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const [lanesMatrixSettings, setLanesMatrixSettings] = useState<TMatrixSettingItem[]>([]);

  useEffect(() => {
    setLanesMatrixSettings(opticalMatrixSettings.filter((el) => el.scanConfig));
  }, [opticalMatrixSettings]);

  if (!opticalMatrixSettings.some((el) => el.scanConfig)) {
    return (
      <NoDataFound
        size="big"
        textData="There are no optic settings for matrix view or no components with scan"
        className="center-block"
      />
    );
  }

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('matrix-view__table')}
      bodyClassName={cn('matrix-view__body')}
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column>LANE</RunDesignTable.Column>
          <RunDesignTable.Column>SCAN </RunDesignTable.Column>
          <RunDesignTable.Column>
            <span />
          </RunDesignTable.Column>
          <RunDesignTable.Column>EX. CHANNEL </RunDesignTable.Column>
          <RunDesignTable.Column>EM. FILTER </RunDesignTable.Column>
          <RunDesignTable.Column>INTENSITY </RunDesignTable.Column>
          <RunDesignTable.Column>EXP(MS) </RunDesignTable.Column>
          <RunDesignTable.Column>
            <span />
          </RunDesignTable.Column>
          <RunDesignTable.Column>REAGENT/CELL TYPE </RunDesignTable.Column>
          <RunDesignTable.Column>ASSAY </RunDesignTable.Column>
        </RunDesignTable.Row>
      }
    >
      {lanesMatrixSettings.map((row) => {
        const reagentInfo = isReagentConsumable(row.consumable) ? row.consumable : null;
        const excitationWavelength = reagentInfo?.excitationWavelength ?? 0;
        const detectionWavelength = reagentInfo?.detectionWavelength ?? 0;

        const waveInfo = getWaveUiInfo(excitationWavelength, detectionWavelength);
        return (
          <RunDesignTable.Row key={row.id}>
            <RunDesignTable.Column>
              <span className={cn('lane')}>{row.lane.laneId}</span>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <span className={cn('scan')}>{row.scanConfig?.scanIds[0] ?? MDASH}</span>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <ReagentCell color={waveInfo.ex.color} isEx>
                {waveInfo.ex.uiLabel} ({excitationWavelength})
              </ReagentCell>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <ReagentCell color={waveInfo.em.color}>
                {waveInfo.em.uiLabel} ({detectionWavelength})
              </ReagentCell>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <NumberInput
                className={cn('matrix-view__input')}
                value={0}
                min={0}
                max={100}
                customRegex={DecimalRegex}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <NumberInput
                className={cn('matrix-view__input')}
                value={0}
                min={0}
                max={5000}
                customRegex={DecimalRegex}
              />
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <span className={cn('arrow')}>
                <icons.ArrowPointerIcon />
              </span>
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              {reagentInfo && <ReagentComponent consumable={reagentInfo} />}
            </RunDesignTable.Column>
            <RunDesignTable.Column>
              <span className={cn('assay')}>
                <span className={cn('assay__icon')}>{row.assayIcon ?? MDASH}</span>
                <span className={cn('assay__title')}>{row.componentName}</span>
              </span>
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default MatrixView;
