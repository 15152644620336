import { FC } from 'react';
import classNames from 'classnames/bind';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { EScanTotal, TScanTotal } from '../types';
import Table from './Table';
import styles from '../MainBlock.module.scss';

const cn = classNames.bind(styles);

type TSingleScanContentProps = {
  scanOrder: number;
  scanTotal: {
    value: string;
    onChange: (value: TScanTotal) => void;
  };
};

const SingleScanContent: FC<TSingleScanContentProps> = ({ scanOrder, scanTotal }) => (
  <div className={cn('main-block__content')}>
    <div className={cn('main-block__sub-column')}>
      <div className={cn('main-block__block')}>
        <div className={cn('main-block__group')}>
          <div className={cn('main-block__block-title')}>scans total</div>
          <Select
            options={[
              { value: EScanTotal.single, label: 'Single after completion' },
              { value: EScanTotal.multi, label: 'Multiple scans' },
            ]}
            value={scanTotal.value}
            onChange={scanTotal.onChange}
            theme={themeOptions.light}
            placeholder="Select scan total..."
            className={cn('main-block__select')}
            controlClassName={cn('main-block__select-control')}
            menuClassName={cn('main-block__select-menu')}
            isPortalable={false}
          />
        </div>
      </div>
      <Table
        heading={['scan number', 'scan type']}
        content={[
          [
            <span className={cn('main-block__block-text')} key={5}>
              {scanOrder}
            </span>,
            <span className={cn('main-block__scan-type')} key={51}>
              After step completion
            </span>,
          ],
        ]}
      />
    </div>
  </div>
);

export default SingleScanContent;
