import { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';

import { Reagent } from '@/graphql/API';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { EAnnotationType } from '@/store/services/annotation/endpoints/types';

import ReagentOption from '@/components/runDesign/ReagentComponent/ReagentOption';
import AddToTableButton from '@/components/runDesign/AddToTableButton';
import ReagentComponent from '@/components/runDesign/ReagentComponent';
import reagentStyles from '@/components/runDesign/ReagentComponent/Reagent.module.scss';
import { TRunDesignCellType } from '@/store/slices/experimentRunDesign';

import styles from '../../EditCellType.module.scss';
import useAsyncSelectAnnotation from '../../../../hooks/useAsynSelectAnnotation';
import SampleInformationAsyncSelect from '../SampleInformationAsyncSelect';

const cn = classNames.bind({ ...styles, ...reagentStyles });

type TEditCellTypePreLabelingProps = {
  reagent?: Nullable<Reagent>;
  cellType?: Nullable<TRunDesignCellType>;
  laneIndex: number;
  onChangeReagent: (runDesignCardIndex: number, reagentData: Nullable<Reagent>) => void;
};

const EditCellTypePreLabeling: FC<TEditCellTypePreLabelingProps> = ({
  reagent,
  laneIndex,
  onChangeReagent,
  cellType,
}) => {
  const onChange = useCallback(
    (selectedReagent: Nullable<Reagent>) => {
      onChangeReagent(laneIndex, selectedReagent);
    },
    [onChangeReagent, laneIndex]
  );

  const {
    selectRef,
    isEditSelectMode,
    handleSelectedNameChange,
    loadOptions,
    noOptionsMessage,
    selectedValue,
    handleSetEditMode,
  } = useAsyncSelectAnnotation<Reagent>({
    annotation: reagent,
    onChange,
    annotationType: EAnnotationType.reagent,
    annotationLabelKey: 'name',
    annotationValueKey: 'id',
  });

  useEffect(() => {
    handleSetEditMode(false);
  }, [reagent]);

  const handleClick = useCallback(() => {
    handleSetEditMode(true);
  }, [handleSetEditMode]);

  const onBlur = useCallback(() => {
    handleSetEditMode(false);
  }, [handleSetEditMode]);

  const onAddClick = useCallback(() => {
    handleSetEditMode(true);
  }, [handleSetEditMode]);

  if (!cellType) {
    return null;
  }

  if (!isEditSelectMode && !reagent) {
    return (
      <div
        className={cn('cell-content', 'pre-labeling__btn', SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 3, dataType: 'preLabeling' })}
      >
        <AddToTableButton onClick={onAddClick} text="Add reagent" />
      </div>
    );
  }

  if (!isEditSelectMode && reagent) {
    return (
      <div
        className={cn('cell-content', 'pre-labeling__btn', SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 3, dataType: 'preLabeling' })}
      >
        <ReagentComponent onClick={handleClick} consumable={reagent} />
      </div>
    );
  }

  return (
    <SampleInformationAsyncSelect
      selectRef={selectRef}
      value={selectedValue}
      onBlur={onBlur}
      noOptionsMessage={noOptionsMessage}
      onChange={handleSelectedNameChange}
      loadOptions={loadOptions}
      components={{ Option: ReagentOption }}
      menuClassName={cn('select-menu')}
      isClearable
    />
  );
};

export default EditCellTypePreLabeling;
