import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { CellFunction } from '@/graphql/API';

import { addTooltip, NDASH } from '@/helpers';
import { isCompoundConsumable, isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ReagentComponent from '@/components/runDesign/ReagentComponent';
import ReagentHeader from '@/components/runDesign/ReagentComponent/ReagentHeader';

import styles from './MinimizedCellType.module.scss';

const cn = classnames.bind(styles);

type TMinimizedCellType = {
  runDesignCardIndex: number;
  isExpandMode?: boolean;
  showFunctionBlock?: boolean;
};

const getFunctionUIName = (functionName: CellFunction): string => {
  const functionMap: Record<CellFunction, string> = {
    EFFECTOR: 'Effector',
    OTHER: 'Other',
    TARGET: 'Target',
  };

  return functionMap[functionName] ?? NDASH;
};

const MinimizedCellType: FC<TMinimizedCellType> = ({ runDesignCardIndex, isExpandMode, showFunctionBlock }) => {
  const cellTypes = useSelector(experimentRunDesignSelectors.selectCellTypeListByIndex(runDesignCardIndex));
  const isPreLabelingEX = useSelector(experimentRunDesignSelectors.selectIsPreLabelingEX(runDesignCardIndex));
  const isPreTreatmentEX = useSelector(experimentRunDesignSelectors.selectIsPreTreatmentEX(runDesignCardIndex));
  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);
  const showFullLabels = useMemo(() => isExpandMode || cellTypesCount === 1, [isExpandMode, cellTypesCount]);

  return (
    <RunDesignTable
      tableData={[]}
      className={cn('minimized-cell-type__table')}
      header={
        <RunDesignTable.Row>
          <RunDesignTable.Column className={cn('minimized-cell-type__header-title')}>Name, FCR</RunDesignTable.Column>
          {isPreTreatmentEX && (
            <RunDesignTable.Column>
              <ReagentHeader
                className={cn({
                  'minimized-cell-type__header-title_no-uppercase': !showFullLabels,
                })}
                withWaves={isPreTreatmentEX}
              >
                {showFullLabels ? 'Pre-Treatment' : 'pTRT'}
              </ReagentHeader>
            </RunDesignTable.Column>
          )}
          {isPreLabelingEX && (
            <RunDesignTable.Column>
              <ReagentHeader
                className={cn({
                  'minimized-cell-type__header-title_no-uppercase': !showFullLabels,
                })}
                withWaves={isPreLabelingEX}
              >
                {showFullLabels ? 'Pre-Labeling' : 'pLBL'}
              </ReagentHeader>
            </RunDesignTable.Column>
          )}
        </RunDesignTable.Row>
      }
    >
      {cellTypes?.map((cellType, index) => {
        const key = index;
        if (!cellType) {
          return <RunDesignTable.Row key={key} />;
        }

        const functionName = getFunctionUIName(cellType.function);

        return (
          <RunDesignTable.Row key={key}>
            <RunDesignTable.Column className={cn('cell')}>
              <div
                className={cn('cell__content', 'cell__content_name', {
                  'cell__content_on-full-height': !showFunctionBlock,
                })}
              >
                <span {...addTooltip(cellType?.cellIndex?.name ?? '')} className={cn('ellipsis-text')}>
                  {cellType?.cellIndex?.name}
                </span>
                {showFunctionBlock && functionName && (
                  <span {...addTooltip(functionName)} className={cn('ellipsis-text')}>
                    {functionName}
                  </span>
                )}
              </div>
            </RunDesignTable.Column>

            {isPreTreatmentEX && (
              <RunDesignTable.Column className={cn('cell')}>
                {cellType?.preTreatment?.consumable &&
                (isReagentConsumable(cellType?.preTreatment?.consumable) ||
                  isCompoundConsumable(cellType?.preTreatment?.consumable)) ? (
                  <ReagentComponent className={cn('cell__content')} consumable={cellType?.preTreatment?.consumable} />
                ) : (
                  <div />
                )}
              </RunDesignTable.Column>
            )}
            <RunDesignTable.Column className={cn('cell')}>
              {cellType?.preLabeling?.consumable && isReagentConsumable(cellType?.preLabeling?.consumable) ? (
                <ReagentComponent className={cn('cell__content')} consumable={cellType?.preLabeling?.consumable} />
              ) : (
                <div />
              )}
            </RunDesignTable.Column>
          </RunDesignTable.Row>
        );
      })}
    </RunDesignTable>
  );
};

export default MinimizedCellType;
