import { FC } from 'react';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';
import NoDataFound from '@/components/common/NoDataFound';

import ReagentsForAssaysBlock from './components/ReagentsForAssaysBlock';

const ReagentsForAssays: FC = () => {
  const componentList = useSelector(experimentRunDesignSelectors.selectAssayComponentList);

  return (
    <RunDesignBlockWithLanes title="Select reagents for assays">
      {componentList.length === 0 ? (
        <NoDataFound size="big" textData="There are no components" className="center-block" />
      ) : (
        <ReagentsForAssaysBlock componentList={componentList} />
      )}
    </RunDesignBlockWithLanes>
  );
};

export default ReagentsForAssays;
