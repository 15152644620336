import { Placement } from '@/graphql/API';
import { getComponentListWithNewIncubation } from '@/store/slices/experimentRunDesign/helpers';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';
import fixPlacement from './helpers/fixPlacement';
import copyState from './helpers/copyState';
import getCurrentComponentIndex from './helpers/getCurrentComponentIndex';
import { EOrderActionList, TOrderReducerAction } from './types';

const updateTimelinePlacement = (
  state: TRunDesignComponent[],
  placementIndex: number,
  placement: Placement
): TRunDesignComponent[] => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];
  component.timing.placement = placement;

  newState = newState.toSpliced(placementIndex, 1);
  let relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  if (relativeToIndex < 0) {
    relativeToIndex = 0;
    component.timing.relativeTo = newState[0]?.id;
  }

  const reorderActions = {
    [Placement.START]: () => {
      newState = [component, ...newState];
    },
    [Placement.END]: () => {
      newState = [...newState, component];
    },
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  return newState;
};

const updateTimelineRelativeTo = (state: TRunDesignComponent[], placementIndex: number, relativeTo: string) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  const component = newState[placementIndex];
  component.timing.relativeTo = relativeTo;

  newState = newState.toSpliced(placementIndex, 1);
  const relativeToIndex = newState.findIndex(({ id }) => component.timing.relativeTo === id);

  const reorderActions = {
    [Placement.BEFORE]: () => {
      newState = newState.toSpliced(relativeToIndex, 0, component);
    },
    [Placement.AFTER]: () => {
      newState = newState.toSpliced(relativeToIndex + 1, 0, component);
    },
    [Placement.SIMULTANEOUS]: () => {
      newState = [...newState, component];
    },
  };

  const { placement } = component.timing;

  if (placement in reorderActions) {
    reorderActions[placement as keyof typeof reorderActions]();
    newState = fixPlacement(newState);
  }

  return newState;
};

const addIncubation = (state: TRunDesignComponent[], placementIndex: number, id: string) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));
  newState = getComponentListWithNewIncubation(newState, id, placementIndex);
  fixPlacement(newState);

  return newState;
};

const removeComponent = (state: TRunDesignComponent[], placementIndex: number) => {
  let newState = copyState(state, (component) => ({ ...component, timing: { ...component.timing } }));

  newState = newState.toSpliced(placementIndex, 1);
  fixPlacement(newState);

  return newState;
};

const orderReducer = (state: TRunDesignComponent[], action: TOrderReducerAction) => {
  const placementIndex = getCurrentComponentIndex(state, action.payload.id);

  if (action.type === EOrderActionList.updateTimelinePlacement) {
    return updateTimelinePlacement(state, placementIndex, action.payload.value);
  }

  if (action.type === EOrderActionList.updateTimelineRelativeTo) {
    return updateTimelineRelativeTo(state, placementIndex, action.payload.value);
  }

  if (action.type === EOrderActionList.addIncubation) {
    return addIncubation(state, placementIndex, action.payload.id);
  }

  if (action.type === EOrderActionList.removeIncubation) {
    return removeComponent(state, placementIndex);
  }

  throw new Error('Unknown edit order action.');
};

export default orderReducer;
