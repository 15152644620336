import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import CustomHeaderWithActions, { TCustomHeaderAction } from '@/components/runDesign/CustomHeaderWithActions';

const CustomPageHeader: FC = () => {
  const appDispatch = useAppDispatch();
  const isGlobalSettingsView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);

  const actions = useMemo<TCustomHeaderAction[]>(() => {
    const result: TCustomHeaderAction[] = [
      {
        onClick: () => {
          appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(true));
        },
        title: 'Global',
        isActive: isGlobalSettingsView,
      },
      {
        onClick: () => {
          appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(false));
        },
        title: 'Matrix view',
        isActive: !isGlobalSettingsView,
      },
    ];

    return result;
  }, [isGlobalSettingsView]);

  return <CustomHeaderWithActions actions={actions}>Edit optical settings</CustomHeaderWithActions>;
};

export default CustomPageHeader;
