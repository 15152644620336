import { Placement, TemperatureUnit } from '@/graphql/API';
import { TTimeInputUnit } from '@/components/common/TimeInput';
import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

export const EOrderActionList = {
  updateTimelinePlacement: 'order/updateTimelinePlacement',
  updateTimelineRelativeTo: 'order/updateTimelineRelativeTo',
  addIncubation: 'order/addIncubation',
  removeIncubation: 'order/removeIncubation',
} as const;

export const EIncubationActionList = {
  updateTime: 'incubation/updateTime',
  updateTemperature: 'incubation/updateTemperature',
  updateTemperatureUnit: 'incubation/updateTemperatureUnit',
  updateLocationOnDevice: 'incubation/updateLocationOnDevice',
} as const;

export const EWashActionList = {
  updateTime: 'wash/updateTime',
  updateTemperature: 'wash/updateTemperature',
  updateTemperatureUnit: 'wash/updateTemperatureUnit',
  updateNumberOfWashes: 'wash/updateNumberOfWashes',
} as const;

export const EScanActionList = {
  updateNumberOfScans: 'scan/updateNumberOfScans',
  updateInterval: 'scan/updateInterval',
  updateFirstScanStart: 'scan/updateFirstScanStart',
} as const;

export type TTimeChangePayload = {
  value: number;
  unit: TTimeInputUnit;
};

type TChangeComponentAction = {
  type: 'changeComponentList';
  payload: TRunDesignComponent[];
};

type TUpdateTimelinePlacement = {
  type: typeof EOrderActionList.updateTimelinePlacement;
  payload: {
    id: string;
    value: Placement;
  };
};

type TUpdateTimelineRelativeTo = {
  type: typeof EOrderActionList.updateTimelineRelativeTo;
  payload: {
    id: string;
    value: string;
  };
};

type TAddIncubation = {
  type: typeof EOrderActionList.addIncubation;
  payload: {
    id: string;
  };
};

type TRemoveIncubation = {
  type: typeof EOrderActionList.removeIncubation;
  payload: {
    id: string;
  };
};

export type TOrderReducerAction =
  | TUpdateTimelinePlacement
  | TUpdateTimelineRelativeTo
  | TAddIncubation
  | TRemoveIncubation;

type TUpdatePauseAfterCompletion = {
  type: 'updatePauseAfterCompletion';
  payload: {
    id: string;
    value: boolean;
  };
};

type TUpdateIncubationTime = {
  type: typeof EIncubationActionList.updateTime;
  payload: {
    id: string;
  } & TTimeChangePayload;
};

type TUpdateIncubationTemperatureDegree = {
  type: typeof EIncubationActionList.updateTemperature;
  payload: {
    id: string;
    value: number;
  };
};

type TUpdateIncubationTemperatureUnit = {
  type: typeof EIncubationActionList.updateTemperatureUnit;
  payload: {
    id: string;
    value: TemperatureUnit;
  };
};

type TUpdateIncubationLocationOnDevice = {
  type: typeof EIncubationActionList.updateLocationOnDevice;
  payload: {
    id: string;
    value: boolean;
  };
};

export type TIncubationReducerAction =
  | TUpdateIncubationTime
  | TUpdateIncubationTemperatureDegree
  | TUpdateIncubationTemperatureUnit
  | TUpdateIncubationLocationOnDevice;

type TUpdateWashTime = {
  type: typeof EWashActionList.updateTime;
  payload: {
    id: string;
  } & TTimeChangePayload;
};

type TUpdateWashTemperatureDegree = {
  type: typeof EWashActionList.updateTemperature;
  payload: {
    id: string;
    value: number;
  };
};

type TUpdateWashTemperatureUnit = {
  type: typeof EWashActionList.updateTemperatureUnit;
  payload: {
    id: string;
    value: TemperatureUnit;
  };
};

type TUpdateWashNumberOfWashes = {
  type: typeof EWashActionList.updateNumberOfWashes;
  payload: {
    id: string;
    value: number;
  };
};

export type TWashReducerAction =
  | TUpdateWashTime
  | TUpdateWashTemperatureDegree
  | TUpdateWashTemperatureUnit
  | TUpdateWashNumberOfWashes;

type TUpdateScanNumberOfScans = {
  type: typeof EScanActionList.updateNumberOfScans;
  payload: {
    id: string;
    value: number;
  };
};

type TUpdateScanInterval = {
  type: typeof EScanActionList.updateInterval;
  payload: {
    id: string;
  } & TTimeChangePayload;
};

type TUpdateScanStart = {
  type: typeof EScanActionList.updateFirstScanStart;
  payload: {
    id: string;
  } & TTimeChangePayload;
};

export type TScanReducerAction = TUpdateScanNumberOfScans | TUpdateScanInterval | TUpdateScanStart;

export type TEditReducerAction =
  | TChangeComponentAction
  | TOrderReducerAction
  | TUpdatePauseAfterCompletion
  | TIncubationReducerAction
  | TWashReducerAction
  | TScanReducerAction;
