import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';

import styles from './CustomHeaderWithActions.module.scss';

const cn = classnames.bind(styles);

export type TCustomHeaderAction = {
  title: string;
  isActive?: boolean;
  onClick: () => void;
  className?: string;
};

type TCustomHeaderWithActions = {
  actions: TCustomHeaderAction[];
  children: ReactNode;
};

const CustomHeaderWithActions: FC<TCustomHeaderWithActions> = ({ actions, children }) => (
  <div className={cn('header')}>
    <span className={cn('header__title')}>{children}</span>
    <div className={cn('header__actions')}>
      {actions.map((action) => (
        <Button
          key={action.title}
          className={cn('action', { action_active: action.isActive })}
          onClick={action.onClick}
          isOutlined
        >
          {action.title}
        </Button>
      ))}
    </div>
  </div>
);
export default CustomHeaderWithActions;
