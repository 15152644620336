import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import RunDesignTable from '@/components/runDesign/RunDesignTable';

import type { TReagentListByLane, TReagentListByLaneList } from '../types';
import styles from '../ReagentsCard.module.scss';
import ReagentCell, { TReagentCell, TReagentCellClick } from './ReagentCell';

const cn = classnames.bind(styles);

type TReagentsRow = {
  reagentListByLane: TReagentListByLane;
  allReagentsData: TReagentListByLaneList;
  isEdit?: boolean;
  className?: string;
  rowIndex: number;
  updateCurrentReagentList: Dispatch<SetStateAction<TReagentListByLaneList>>;
  withWaves?: boolean;
};

const ReagentsRow: FC<TReagentsRow> = ({
  reagentListByLane,
  className,
  isEdit,
  updateCurrentReagentList,
  allReagentsData,
  rowIndex,
  withWaves,
}) => {
  const onPreselectedClick = useCallback<TReagentCellClick>(
    (preselectedReagent, { reagentIndex }) => {
      if (!preselectedReagent?.consumable.id) {
        return;
      }
      updateCurrentReagentList((prev) =>
        prev.map((reagentsByLane) => {
          if (reagentsByLane.laneLetter !== reagentListByLane.laneLetter) {
            return reagentsByLane;
          }

          reagentsByLane.reagents[reagentIndex] = preselectedReagent;
          return reagentsByLane;
        })
      );
    },
    [reagentListByLane.laneLetter, reagentListByLane.componentId]
  );

  const onAddNew = useCallback<TReagentCellClick>(
    (newReagent, { componentId, laneId, reagentIndex }) => {
      updateCurrentReagentList((prev) =>
        prev.map((reagentsByLane) => {
          if (reagentsByLane.componentId === componentId && reagentsByLane.laneLetter === laneId) {
            reagentsByLane.reagents[reagentIndex] = newReagent;
          }
          return reagentsByLane;
        })
      );
    },
    [reagentListByLane.laneLetter, reagentListByLane.componentId]
  );

  const getPreselectedUsedReagent = useCallback(
    (index: number) => allReagentsData.find((byLanes) => byLanes.reagents?.[index]?.consumable.id)?.reagents[index],
    [allReagentsData, reagentListByLane]
  );

  const reagentCellList = useMemo(
    () =>
      Array.from(
        {
          length: Math.max(
            0,
            ...allReagentsData.map((el) => 1 + el.reagents.findLastIndex((reagent) => !!reagent?.consumable.id))
          ),
        },
        (_, i) => i
      ),
    [allReagentsData]
  );

  const reagentCellDefaultProps = useMemo<TReagentCell>(
    () => ({
      componentId: reagentListByLane.componentId,
      laneId: reagentListByLane.laneLetter,
      onAddNew,
      onPreselectedClick,
      isEdit,
      usedReagentIndex: 0,
    }),
    [reagentListByLane, onAddNew, onPreselectedClick, isEdit]
  );

  return (
    <RunDesignTable.Row className={cn(className)}>
      <>
        {reagentCellList.map((cellIndex) => {
          const reagentKey = cellIndex;
          const usedReagent = reagentListByLane.reagents?.[cellIndex]?.consumable.id
            ? reagentListByLane.reagents[cellIndex]
            : null;
          const preselectedReagent = getPreselectedUsedReagent(cellIndex);

          return (
            <RunDesignTable.Column key={reagentKey}>
              <div
                className={isEdit ? SELECTABLE_CLASS_NAME : ''}
                {...addSelectableAttribute({ rowIndex, columnIndex: cellIndex, dataType: 'reagent' })}
              >
                <ReagentCell
                  {...reagentCellDefaultProps}
                  preselectedUsedReagent={preselectedReagent}
                  usedReagentIndex={cellIndex}
                  usedReagent={usedReagent}
                  withWaves={withWaves}
                />
              </div>
            </RunDesignTable.Column>
          );
        })}
        <RunDesignTable.Column>
          <div
            className={isEdit ? SELECTABLE_CLASS_NAME : ''}
            {...addSelectableAttribute({ rowIndex, columnIndex: reagentCellList.length, dataType: 'reagent' })}
          >
            <ReagentCell {...reagentCellDefaultProps} usedReagentIndex={reagentCellList.length} />
          </div>
        </RunDesignTable.Column>
      </>
    </RunDesignTable.Row>
  );
};

export default ReagentsRow;
