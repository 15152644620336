import { FC, useCallback } from 'react';
import classNames from 'classnames/bind';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { CellIndex } from '@/graphql/API';
import { EAnnotationType } from '@/store/services/annotation/endpoints/types';
import { TRunDesignCellType } from '@/store/slices/experimentRunDesign';

import AddToTableButton from '@/components/runDesign/AddToTableButton';

import SampleInformationAsyncSelect from '../SampleInformationAsyncSelect';
import useAsyncSelectAnnotation from '../../../../hooks/useAsynSelectAnnotation';

import styles from '../../EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeNameProps = {
  cellType?: Nullable<TRunDesignCellType>;
  laneIndex: number;
  onNameChange: (runDesignCardIndex: number, name: string, id: string) => void;
};

const EditCellTypeName: FC<TEditCellTypeNameProps> = ({ cellType, onNameChange, laneIndex }) => {
  const handleChange = useCallback(
    (data: Nullable<CellIndex>) => {
      if (!data) {
        return;
      }
      onNameChange(laneIndex, data.name, data.id);
    },
    [laneIndex, onNameChange]
  );

  const {
    selectRef,
    isEditSelectMode,
    handleChangeEditMode,
    handleSelectedNameChange,
    loadOptions,
    noOptionsMessage,
    handleSetEditMode,
    selectedValue,
  } = useAsyncSelectAnnotation<CellIndex>({
    annotation: cellType?.cellIndex,
    onChange: handleChange,
    annotationType: EAnnotationType.cellIndex,
    annotationLabelKey: 'name',
    annotationValueKey: 'name',
  });

  const onBlur = useCallback(() => {
    handleSetEditMode(false);
  }, [handleSetEditMode]);

  if (!isEditSelectMode && !cellType?.cellIndex?.name) {
    return <AddToTableButton onClick={handleChangeEditMode} text="Add name" />;
  }

  return (
    <div
      className={cn('cell-content', 'cell-content_name', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 0, dataType: 'name' })}
    >
      <SampleInformationAsyncSelect
        selectRef={selectRef}
        value={selectedValue}
        noOptionsMessage={noOptionsMessage}
        onChange={handleSelectedNameChange}
        loadOptions={loadOptions}
        onBlur={onBlur}
      />
    </div>
  );
};

export default EditCellTypeName;
