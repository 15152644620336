import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { OpticsSettingsItem } from '@/graphql/API';

import { removeDuplicates } from '@/helpers';
import { isReagentConsumable } from '@/helpers/runDesigns/typeGuards';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import NoDataFound from '@/components/common/NoDataFound';

import styles from './GlobalView.module.scss';
import GlobalSettingRow from './GlobalSettingRow';
import { RunDesignContext } from '../../../context';

const cn = classnames.bind(styles);

type TGlobalView = {
  isEditMode: boolean;
};

const GlobalView: FC<TGlobalView> = ({ isEditMode }) => {
  const appDispatch = useAppDispatch();
  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const globalOpticalSettings = useSelector(experimentRunDesignSelectors.selectGlobalOpticalSettings);
  const opticalMatrixSettings = useSelector(experimentRunDesignSelectors.selectOpticalMatrixSettings);

  const [opticalSettings, setOpticalSettings] = useState<OpticsSettingsItem[]>(globalOpticalSettings ?? []);

  useEffect(() => {
    if (!opticalMatrixSettings?.length) {
      return;
    }

    if (!globalOpticalSettings?.length) {
      setOpticalSettings(
        removeDuplicates(opticalMatrixSettings, 'reagentId').map((setting) => {
          const reagentInfo = isReagentConsumable(setting.consumable) ? setting.consumable : null;
          return {
            __typename: 'OpticsSettingsItem',
            detectionWavelength: reagentInfo?.detectionWavelength ?? 0,
            excitationWavelength: reagentInfo?.excitationWavelength ?? 0,
            exposure: 0,
            intensity: 0,
          };
        })
      );
      return;
    }
    setOpticalSettings(globalOpticalSettings);
  }, [globalOpticalSettings, opticalMatrixSettings]);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateGlobalOpticalSettings(opticalSettings));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
          appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(true));
        },
      },
    });
  }, [overrideCustomFooterConfig, opticalSettings]);

  const handleChangeGlobalSetting = useCallback(
    (index: number) => (updatedSettings: OpticsSettingsItem) => {
      const copyOpticalSettings = [...opticalSettings];
      copyOpticalSettings[index] = { ...updatedSettings };
      setOpticalSettings(copyOpticalSettings);
    },
    [opticalSettings]
  );

  if (!opticalMatrixSettings.length) {
    return <NoDataFound size="big" textData="There are no optic settings" className="center-block" />;
  }

  return (
    <RunDesignTable
      className={cn('global-view__table')}
      bodyClassName={cn('global-view__body')}
      tableData={[]}
      header={
        <RunDesignTable.Row className={cn('global-view__header')}>
          <RunDesignTable.Column>EX. CHANNEL</RunDesignTable.Column>
          <RunDesignTable.Column>EM. FILTER</RunDesignTable.Column>
          <RunDesignTable.Column>INTENSITY</RunDesignTable.Column>
          <RunDesignTable.Column>EXP (MS)</RunDesignTable.Column>
        </RunDesignTable.Row>
      }
    >
      {opticalSettings.map((globalSetting, globalSettingIndex) => {
        const key = globalSettingIndex;
        return (
          <GlobalSettingRow
            updateSettings={handleChangeGlobalSetting(globalSettingIndex)}
            isEditMode={isEditMode}
            settings={globalSetting}
            // index={globalSettingIndex}
            key={key}
          />
        );
      })}
    </RunDesignTable>
  );
};

export default GlobalView;
