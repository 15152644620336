import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash.isequal';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { createRunDesignFromTemplateSchema, updateRunDesignSchema } from '@/validationSchemas';

import { getErrorsByFieldsFromSchema } from '@/helpers/validator';

import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';

import * as queries from '@/graphql/queries';
import {
  CreateRunDesignFromTemplateInput,
  ListRunDesignsInput,
  RunDesignEdge,
  UpdateRunDesignInput,
} from '@/graphql/API';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

export function useEditDesignMethods() {
  const { sourceType } = useParams();
  const isDraft = sourceType === ERunDesignSourceType.draft;

  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const initialEditFields = useSelector(experimentRunDesignSelectors.selectInitialEditFields);
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const { edgeList: runDesignEdgeList } = useGraphqlQueryFullList<ListRunDesignsInput, RunDesignEdge>({
    query: queries.runDesigns,
    dataName: 'runDesigns',
  });

  const getCreateInputAndErrors = useCallback(() => {
    const input = {
      templateId: designData.templateId,
      name: currentEditFields.name,
      description: currentEditFields.description,
      investigatorId: currentEditFields.investigatorId,
      projectId: currentEditFields.projectId,
      externalLinks: currentEditFields.externalLinks,
      organisms: currentEditFields.organisms,
    } as CreateRunDesignFromTemplateInput;

    const validationErrors = getErrorsByFieldsFromSchema(input, createRunDesignFromTemplateSchema);

    if (
      runDesignEdgeList
        .map((edge) => edge.node.name.trim().toLowerCase())
        .includes(currentEditFields.name.trim().toLowerCase())
    ) {
      validationErrors.name = 'Run design with the same name is already exists';
    }

    return { input, validationErrors };
  }, [designData.templateId, currentEditFields, runDesignEdgeList]);

  const getUpdateInputAndErrors = useCallback(() => {
    const input: UpdateRunDesignInput = {};
    if (initialEditFields.name !== currentEditFields.name) {
      input.name = currentEditFields.name;
    }
    if (initialEditFields.description !== currentEditFields.description) {
      input.description = currentEditFields.description;
    }
    if (initialEditFields.investigatorId !== currentEditFields.investigatorId) {
      input.investigatorId = currentEditFields.investigatorId;
    }
    if (initialEditFields.projectId !== currentEditFields.projectId) {
      input.projectId = currentEditFields.projectId;
    }
    if (!isEqual(initialEditFields.externalLinks, currentEditFields.externalLinks)) {
      input.externalLinks = currentEditFields.externalLinks;
    }
    if (!isEqual(initialEditFields.organisms, currentEditFields.organisms)) {
      input.organisms = currentEditFields.organisms;
    }
    if (!isEqual(initialEditFields.schema, currentEditFields.schema)) {
      input.schema = JSON.stringify(currentEditFields.schema);
    }

    const validationErrors = getErrorsByFieldsFromSchema(input, updateRunDesignSchema);

    if (
      runDesignEdgeList
        .filter((edge) => edge.node.id !== designData.runDesignId)
        .map((edge) => edge.node.name.trim().toLowerCase())
        .includes(currentEditFields.name.trim().toLowerCase())
    ) {
      validationErrors.name = 'Run design with the same name is already exists';
    }

    return { input, validationErrors };
  }, [initialEditFields, currentEditFields, runDesignEdgeList, designData.runDesignId]);

  return useMemo(
    () => ({
      isDraft,
      getCreateInputAndErrors,
      getUpdateInputAndErrors,
    }),
    [isDraft, getCreateInputAndErrors, getUpdateInputAndErrors]
  );
}
