import { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';

import { iconList } from '@/helpers/runDesigns/constants';

import { TCardData } from '../types';
import { formatDurationText } from '../helpers';

import styles from './TimelineCard.module.scss';

const cn = classNames.bind(styles);

type TTimelineCardProps = {
  className?: string;
  data: TCardData;
  onClick: () => void;
  position: {
    row: number;
    column: number;
    columnEnd?: number;
  };
};

const TimelineCard: FC<TTimelineCardProps> = ({ className, data, onClick, position }) => (
  <button
    className={cn(
      'timeline-card',
      { 'timeline-card_simultaneous': position.row > 1, 'timeline-card_no-scan': !data.scan },
      className
    )}
    onClick={onClick}
    style={{ '--row': position.row, '--column': position.column, '--column-end': position.columnEnd } as CSSProperties}
  >
    <div className={cn('timeline-card__wrap')}>
      <div className={cn('timeline-card__block', 'timeline-card__block_default')}>
        <div className={cn('timeline-card__header')}>
          <h3 className={cn('timeline-card__title')}>{data.title}</h3>
          <p className={cn('timeline-card__duration')}>{formatDurationText(data.durationText)}</p>
        </div>
        {data.iconCollection && (
          <div className={cn('timeline-card__icon-list')}>
            {data.iconCollection.map((iconId) => (
              <div className={cn('timeline-card__icon')} key={iconId}>
                {iconList[iconId]}
              </div>
            ))}
          </div>
        )}
      </div>

      {data.scan && (
        <div className={cn('timeline-card__block', 'timeline-card__block_scan')}>
          <div className={cn('timeline-card__header')}>
            <h3 className={cn('timeline-card__title')}>{data.scan.title}</h3>
            <p className={cn('timeline-card__duration')}>{formatDurationText(data.scan.durationText)}</p>
          </div>
          <div className={cn('timeline-card__icon-list')}>
            {data.scan.iconCollection.map((iconId) => (
              <div className={cn('timeline-card__icon')} key={iconId}>
                {iconList[iconId]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  </button>
);

export default TimelineCard;
