import { v4 as uuidv4 } from 'uuid';
import { Incubation, Placement } from '@/graphql/API';

const getInitialIncubationComponent = (counterInName: number, relativeTo?: string): Incubation => ({
  __typename: 'Incubation',
  id: uuidv4(),
  name: `Incubation ${counterInName}`,
  type: 'Incubation',
  performedOnLanes: [],
  timing: {
    __typename: 'Timing',
    placement: Placement.AFTER,
    relativeTo,
  },
});

export default getInitialIncubationComponent;
