import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import {
  DEFAULT_LANES_COUNT,
  LANE_LETTER_NAME_LIST,
  MIN_CELL_TYPES_COUNT,
  MIN_LANES_COUNT,
  sortByKey,
} from '@/helpers';

import { ConsumableToUse, Lane, LaneReagents, OpticsSettingsItem, RunDesign, RunDesignTemplate } from '@/graphql/API';

import type { TReagentListByLaneList } from '@/pages/experiment-run-design/ReagentsForAssays/components/ReagentsCard/types';
import { DEFAULT_GLOBAL_SETTINGS } from '@/pages/experiment-run-design/CagingSettings/constants';

import {
  TExperimentRunDesignState,
  TRunDesignCagingSettings,
  TRunDesignComponent,
  TRunDesignEditFields,
} from './types';

import {
  getAvailableId,
  removeLaneFromComponents,
  removeFromLanes,
  updateLanesSettings,
  getParsedCagingSettings,
  getParsedLanesData,
  updateSampleNames,
  clearGeneratedSampleNames,
  getComponentListWithNewIncubation,
  removeIncubation,
} from './helpers';
import { graphqlAPI } from '../../services/graphql';

const initialFieldsState = {
  name: '',
  description: '',
  investigatorId: '',
  projectId: '',
  externalLinks: [],
  organisms: [],
  schema: null,
};

const initialState: TExperimentRunDesignState = {
  source: {
    type: null,
    id: null,
  },
  data: {
    templateId: null,
    templateName: null,
    runDesignId: null,
    runDesignDate: null,
  },
  editFields: {
    initial: initialFieldsState,
    current: initialFieldsState,
  },
  addData: {
    runDesignCardIndexEdit: -1,
    runDesignCardIndexExpand: -1,
    isAdvancedMode: false,
    isGlobalSettingsView: true,
    isAutoGenerateSampleNames: false,
    isSampleNamesGenerated: false,
  },
};

const experimentRunDesignSlice = createSlice({
  name: 'experimentRunDesign',
  initialState,
  reducers: {
    clear: (state) => {
      state.source = {
        type: null,
        id: null,
      };
      state.data = {
        templateId: null,
        templateName: null,
        runDesignId: null,
        runDesignDate: null,
      };
      state.editFields = {
        initial: initialFieldsState,
        current: initialFieldsState,
      };
      state.addData = initialState.addData;
    },

    setSource: (state, action: PayloadAction<{ sourceType?: string; sourceId?: string }>) => {
      const { sourceType, sourceId } = action.payload;
      state.source = {
        type: (sourceType as ERunDesignSourceType) ?? null,
        id: sourceId ?? null,
      };
    },

    setData: (state, action: PayloadAction<{ template?: RunDesignTemplate; runDesign?: RunDesign }>) => {
      const { template, runDesign } = action.payload;
      state.data = {
        templateId: template?.id ?? null,
        templateName: template?.name ?? null,
        runDesignId: runDesign?.id ?? null,
        runDesignDate: runDesign?.createdAt ?? null,
      };
    },

    setEditFieldsFromTemplate: (state, action: PayloadAction<RunDesignTemplate>) => {
      const { name, description } = action.payload;
      const initialFields = {
        ...structuredClone(initialFieldsState),
        name,
        description: description ?? initialFieldsState.description,
        schema: initialFieldsState.schema,
      };
      state.editFields = { initial: initialFields, current: initialFields };
    },

    setEditFieldsFromRunDesign: (state, action: PayloadAction<RunDesign>) => {
      const { name, description, investigatorId, projectId, externalLinks, organisms, schema } = action.payload;
      const initialFields = {
        name,
        description: description ?? initialFieldsState.description,
        investigatorId,
        projectId,
        externalLinks: externalLinks ?? initialFieldsState.externalLinks,
        organisms: organisms ?? initialFieldsState.organisms,
        schema: structuredClone(schema) ?? initialFieldsState.schema,
      };

      if (initialFields?.schema && !initialFields?.schema.cagingSettings.global) {
        initialFields.schema.cagingSettings = {
          __typename: 'CagingSettings',
          global: {
            __typename: 'CagingSettingsItem',
            ...DEFAULT_GLOBAL_SETTINGS,
          },
          perLane: initialFields.schema.cagingSettings.perLane ?? [],
        };
      } else {
        initialFields.schema.cagingSettings.global = getParsedCagingSettings(
          initialFields?.schema.cagingSettings.global
        );
      }

      // Set one lane with one cell type by default
      if (initialFields.schema?.lanes?.length === 0) {
        for (let i = 0; i < DEFAULT_LANES_COUNT; i++) {
          initialFields.schema.lanes.push({
            __typename: 'Lane',
            id: LANE_LETTER_NAME_LIST[i],
            sample: '',
            cellTypes: [null],
          });
        }
      } else {
        initialFields.schema.lanes = getParsedLanesData(initialFields.schema.lanes);
      }

      state.editFields = { initial: initialFields, current: initialFields };

      if (!initialFields?.schema?.cagingSettings?.perLane.length) {
        updateLanesSettings(state);
      }
    },

    setEditFields: (state, action: PayloadAction<Partial<TRunDesignEditFields>>) => {
      state.editFields.current = { ...state.editFields.current, ...action.payload };
      updateLanesSettings(state);
    },

    addNewLane: (state, action: PayloadAction<string | undefined>) => {
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      const lanes = schema.lanes ?? [];
      const id = getAvailableId(lanes, action.payload);
      if (id) {
        const cellTypes = lanes.length > 0 ? lanes[lanes.length - 1].cellTypes : [];
        lanes.push({ __typename: 'Lane', id, sample: '', cellTypes });
        sortByKey(lanes, 'id');
        state.editFields.current.schema = { ...schema, lanes };
      }
      updateLanesSettings(state);

      if (state.addData.isAutoGenerateSampleNames && state.editFields.current.schema?.lanes?.length) {
        updateSampleNames(state);
      }
    },

    removeLane: (state, action: PayloadAction<string | undefined>) => {
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      const lanes = schema.lanes ?? [];
      if (lanes.length <= MIN_LANES_COUNT) {
        return;
      }

      const laneId = action.payload ?? lanes[lanes.length - 1].id;
      removeFromLanes(lanes, laneId);
      if (schema.components) {
        removeLaneFromComponents(schema.components, laneId);
      }

      state.editFields.current.schema = { ...schema };
      updateLanesSettings(state);
    },

    addNewCellType: (state) => {
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      const lanes = schema.lanes ?? [];
      lanes.forEach((lane) => {
        const cellTypes = lane.cellTypes ?? [];
        cellTypes.push(null);
        lane.cellTypes = cellTypes;
      });
      state.editFields.current.schema = { ...schema, lanes };

      updateLanesSettings(state);
    },

    removeCellType: (state) => {
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      const lanes = schema.lanes ?? [];
      lanes.forEach((lane) => {
        const cellTypes = lane.cellTypes ?? [];

        if (cellTypes.length > MIN_CELL_TYPES_COUNT) {
          cellTypes.pop();
        }
        lane.cellTypes = cellTypes;
      });
      state.editFields.current.schema = { ...schema, lanes };

      updateLanesSettings(state);
    },

    updateLane: (state, action: PayloadAction<{ laneId: string; updatedData: Partial<Lane> }>) => {
      const lanes = state.editFields.current.schema?.lanes;
      if (!lanes) {
        return;
      }
      const { laneId, updatedData } = action.payload;
      const laneToUpdateIndex = lanes.findIndex((lane) => lane.id === laneId);
      if (laneToUpdateIndex < 0) {
        return;
      }
      lanes[laneToUpdateIndex] = {
        ...lanes[laneToUpdateIndex],
        ...updatedData,
      };
      updateLanesSettings(state);
    },

    updateCellTypes: (state, action: PayloadAction<Lane[]>) => {
      const laneList = state.editFields.current.schema?.lanes;
      if (!laneList) {
        return;
      }
      const changedLaneList = action.payload;
      laneList.forEach((lane) => {
        const changedLane = changedLaneList.find(({ id }) => id === lane.id);
        if (changedLane) {
          lane.cellTypes = changedLane.cellTypes;
        }
      });
      updateLanesSettings(state);
    },

    setRunDesignCardIndexEdit: (state, action: PayloadAction<number>) => {
      state.addData.runDesignCardIndexEdit = action.payload;
    },

    setRunDesignCardIndexExpand: (state, action: PayloadAction<number>) => {
      state.addData.runDesignCardIndexExpand = action.payload;
    },

    setIsAdvancedMode: (state, action: PayloadAction<boolean>) => {
      state.addData.isAdvancedMode = action.payload;
    },

    updateComponents: (state, action: PayloadAction<TRunDesignComponent[]>) => {
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      state.editFields.current.schema = { ...schema, components: action.payload };
    },
    addIncubationComponent: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const components = state.editFields.current.schema?.components ?? [];
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      state.editFields.current.schema = { ...schema, components: getComponentListWithNewIncubation(components, id) };
    },
    removeIncubationComponent: (state) => {
      const components = state.editFields.current.schema?.components ?? [];
      const schema = state.editFields.current.schema ?? { __typename: 'RunDesignSchema' };
      state.editFields.current.schema = {
        ...schema,
        components: removeIncubation(components),
      };
    },

    updateComponentsLanesReagents: (state, action: PayloadAction<TReagentListByLaneList>) => {
      const laneList = state.editFields.current.schema?.lanes;
      if (!laneList) {
        return;
      }
      const newData = action.payload;
      const groupedByComponent = Object.groupBy(newData, (el) => el.componentId);

      state.editFields.current.schema?.components?.forEach((component) => {
        const reagentsDataList = groupedByComponent[component.id];
        if (!reagentsDataList) {
          return;
        }
        const newPerformedOnLanes: LaneReagents[] = [];
        laneList.forEach((lane) => {
          const newReagentsData = reagentsDataList.find((reagentsData) => lane.id === reagentsData.laneLetter);
          if (newReagentsData) {
            const oldLaneReagents = component.performedOnLanes.find(
              (laneReagents) => lane.id === laneReagents.laneId
            ) ?? {
              __typename: 'LaneReagents',
              laneId: lane.id,
            };
            newPerformedOnLanes.push({
              ...oldLaneReagents,
              consumables: newReagentsData.reagents as unknown as ConsumableToUse[],
            });
          }
        });
        component.performedOnLanes = newPerformedOnLanes;
      });
    },
    updateGlobalCagingSettings: (state, action: PayloadAction<TRunDesignCagingSettings['global']>) => {
      if (state.editFields.current.schema?.cagingSettings?.global) {
        state.editFields.current.schema.cagingSettings.global = action.payload;
      }
    },
    updateLanesCagingSettings: (state, action: PayloadAction<TRunDesignCagingSettings['perLane']>) => {
      if (state.editFields.current.schema?.cagingSettings) {
        state.editFields.current.schema.cagingSettings.perLane = action.payload;
      }
    },
    updateGlobalOpticalSettings: (state, action: PayloadAction<OpticsSettingsItem[]>) => {
      if (state.editFields.current.schema?.opticsSettings?.global) {
        state.editFields.current.schema.opticsSettings.global = action.payload;
      }
    },
    updateLanesOpticalSettings: (_state, _action: PayloadAction<OpticsSettingsItem[]>) => {
      // empty code, because type structure is not correct
    },
    setIsGlobalSettingsView: (state, action: PayloadAction<boolean>) => {
      state.addData.isGlobalSettingsView = action.payload;
    },
    toggleAutoGenerateSampleNameSwitch: (state) => {
      const newValue = !state.addData.isAutoGenerateSampleNames;
      state.addData.isAutoGenerateSampleNames = newValue;

      if (!newValue || !state.editFields.current.schema?.lanes?.length) {
        clearGeneratedSampleNames(state);
        return;
      }
      updateSampleNames(state);
    },
    setIsAutoGenerateSampleNameSwitch: (state, action: PayloadAction<boolean>) => {
      state.addData.isAutoGenerateSampleNames = action.payload;
    },
    generateSampleNamesForNextSteps: (state) => {
      if (!state.editFields.current.schema?.lanes?.length) {
        return;
      }
      updateSampleNames(state);
      state.addData.isSampleNamesGenerated = true;
    },
    setIsSampleNamesGenerated: (state, action: PayloadAction<boolean>) => {
      state.addData.isSampleNamesGenerated = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(graphqlAPI.endpoints.fetchRunDesign.matchFulfilled, (state, action) => {
      const { id } = action.payload;
      if (state.source.type === ERunDesignSourceType.draft && id === state.source.id) {
        experimentRunDesignSlice.caseReducers.setEditFieldsFromRunDesign(state, action);
      }
    });
  },
});

export default experimentRunDesignSlice;
