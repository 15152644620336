import { FC, MouseEvent, useCallback, useMemo } from 'react';
import classNames from 'classnames/bind';

import { addTooltip, isNumber } from '@/helpers';
import { isReagentConsumable, isStainConsumable } from '@/helpers/runDesigns/typeGuards';
import { Compound, Reagent, Stain } from '@/graphql/API';

import styles from './Reagent.module.scss';
import ReagentWaves from './ReagentWaves';

const cn = classNames.bind(styles);

type TReagentComponent = {
  consumable: Compound | Reagent | Stain;
  className?: string;
  wavesClassName?: string;
  onClick?: Nullable<(consumable: Compound | Reagent | Stain, e: MouseEvent<HTMLButtonElement>) => void>;
  withWaves?: boolean;
};

const ReagentComponent: FC<TReagentComponent> = ({
  consumable,
  onClick,
  className,
  wavesClassName,
  withWaves = true,
}) => {
  const detectionWavelength = useMemo(() => {
    const numberDetectionWavelength =
      isReagentConsumable(consumable) || isStainConsumable(consumable) ? Number(consumable?.detectionWavelength) : 0;

    if (!isNumber(numberDetectionWavelength)) {
      return 0;
    }

    return numberDetectionWavelength;
  }, [consumable]);

  const excitationWavelength = useMemo(() => {
    const numberExcitationWavelength =
      isReagentConsumable(consumable) || isStainConsumable(consumable) ? Number(consumable?.excitationWavelength) : 0;
    if (!isNumber(numberExcitationWavelength)) {
      return 0;
    }

    return numberExcitationWavelength;
  }, [consumable]);

  const componentContent = useMemo(
    () => (
      <>
        <span className={cn('reagent__name')}>{consumable?.name}</span>
        {withWaves && consumable?.name && (
          <ReagentWaves
            detectionWavelength={detectionWavelength}
            excitationWavelength={excitationWavelength}
            className={cn('reagent__waves', wavesClassName)}
          />
        )}
      </>
    ),
    [consumable?.name, excitationWavelength, detectionWavelength]
  );

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(consumable, e);
    },
    [onClick, consumable]
  );

  return (
    <>
      {onClick && (
        <button onClick={handleClick} className={cn('reagent', className)}>
          {componentContent}
        </button>
      )}
      {!onClick && (
        <div {...addTooltip(consumable?.name)} className={cn('reagent', className)}>
          {componentContent}
        </div>
      )}
    </>
  );
};

export default ReagentComponent;
