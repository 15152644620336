import { ChangeEventHandler, FC } from 'react';
import classNames from 'classnames/bind';
import CheckboxInput from '@/components/common/CheckboxInput';
import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';
import { includes } from '@/helpers/enum';
import { Placement } from '@/graphql/API';
import Remover from './Remover';
import styles from './TitleBlock.module.scss';

const cn = classNames.bind(styles);

const PlacementList = {
  [Placement.START]: 'First step of the run',
  [Placement.END]: 'Last step of the run',
  [Placement.BEFORE]: 'Before',
  [Placement.AFTER]: 'After',
  [Placement.SIMULTANEOUS]: 'Simult. w.',
};

const PlacementOptions = Object.entries(PlacementList).map(([value, label]) => ({
  value,
  label,
}));

type TTitleBlockProps = {
  name: string;
  afterCompletion: {
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
  };
  removing: {
    isRemovable: boolean;
    onRemove?: () => void;
  };
  timing: {
    placement: {
      value: Nullable<Placement>;
      onChange: (value: Placement) => void;
    };
    relativeTo: {
      list: TOption[];
      value: Nullable<string>;
      onChange: (value: string) => void;
    };
  };
};

const TitleBlock: FC<TTitleBlockProps> = ({ name, afterCompletion, timing, removing }) => (
  <div className={cn('title-block')}>
    <div className={cn('title-block__column')}>
      <h2 className={cn('title-block__title')}>{name}</h2>
      <div className={cn('title-block__pause-wrap')}>
        <CheckboxInput
          checked={afterCompletion.checked}
          isSwitch
          theme="run-design"
          onChange={afterCompletion.onChange}
        />
        Pause after completion
      </div>
      <Remover {...removing} name={name} />
    </div>
    <div className={cn('title-block__column')}>
      <div className={cn('title-block__step-title')}>Step timing</div>
      <div className={cn('title-block__timing-placement')}>
        <Select
          options={PlacementOptions}
          value={timing.placement.value}
          onChange={timing.placement.onChange}
          theme={themeOptions.light}
          placeholder="Select timing..."
          className={cn('title-block__select')}
          controlClassName={cn('title-block__select-control')}
          menuClassName={cn('title-block__select-menu')}
          isPortalable={false}
        />
        {includes([Placement.AFTER, Placement.BEFORE, Placement.SIMULTANEOUS], timing.placement.value) && (
          <Select
            options={timing.relativeTo.list}
            value={timing.relativeTo.value}
            onChange={timing.relativeTo.onChange}
            theme={themeOptions.light}
            placeholder="Select step..."
            className={cn('title-block__select')}
            controlClassName={cn('title-block__select-control')}
            menuClassName={cn('title-block__select-menu')}
            isPortalable={false}
          />
        )}
      </div>
    </div>
  </div>
);

export default TitleBlock;
