import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames/bind';

import { Reagent, Stain } from '@/graphql/API';

import icons from '@/components/common/icons';

import AddToTableButton from '../AddToTableButton';

import styles from './Reagent.module.scss';

const cn = classnames.bind(styles);

type TPreSelectedReagent = {
  className?: string;
  reagent: Reagent | Stain;
  onClick?: (reagent: Reagent | Stain, e: MouseEvent<HTMLButtonElement>) => void;
  onPlusClick?: MouseEventHandler<HTMLDivElement>;
};

const PreSelectedReagent: FC<TPreSelectedReagent> = ({ reagent, className, onClick, onPlusClick }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(reagent, e);
    },
    [reagent, onClick]
  );

  const handlePlusClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onPlusClick?.(e);
    },
    [onPlusClick]
  );
  return (
    <AddToTableButton className={cn('reagent__btn', className)} onClick={handleClick}>
      <div className={cn('reagent', 'reagent_preselected')}>
        <span className={cn('reagent__name')}>{reagent.name}</span>
        <div className={cn('preselected-reagent__plus-wrapper')}>
          <div
            role="presentation"
            className={cn('preselected-reagent__plus-btn')}
            aria-label="add-preselected-reagent"
            onClick={handlePlusClick}
          >
            <icons.PlusIcon />
          </div>
        </div>
      </div>
    </AddToTableButton>
  );
};

export default PreSelectedReagent;
