import { CSSProperties, FC, MouseEvent, MouseEventHandler, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import IconsAssaySet from '@/components/common/IconsAssaySet';
import {
  DesignCardRenderer,
  isDraftDesignCardData,
  isReadyDesignCardData,
  TDraftDesignCardData,
  TReadyDesignCardData,
  TTemplateDesignCardData,
} from '../../types';
import Slash from './iconList/Slash';
import styles from './DesignCard.module.scss';

const cn = classNames.bind(styles);

type TTemplateDesignCardProps = {
  render?: typeof DesignCardRenderer.template;
  data: TTemplateDesignCardData;
};

type TDraftDesignCardProps = {
  render: typeof DesignCardRenderer.draft;
  data: TDraftDesignCardData;
};

type TReadyDesignCardProps = {
  render: typeof DesignCardRenderer.ready;
  data: TReadyDesignCardData;
};

type TDesignCardProps = TTemplateDesignCardProps | TDraftDesignCardProps | TReadyDesignCardProps;

// data for assay proper display
const iconColorKeyList = {
  [DesignCardRenderer.template]: 'transparent',
  [DesignCardRenderer.draft]: 'light-yellow-invert',
  [DesignCardRenderer.ready]: 'light-yellow-invert',
} as const;

const iconBorderKeyList = {
  [DesignCardRenderer.template]: 'medium',
  [DesignCardRenderer.draft]: 'none',
  [DesignCardRenderer.ready]: 'none',
} as const;

const DesignCard: FC<TDesignCardProps> = ({ render = DesignCardRenderer.template, data }) => {
  const rootRef = useRef<HTMLAnchorElement>(null);
  const [hoverAnimationData, setHoverAnimationData] = useState<{
    x?: number;
    y?: number;
  }>({ x: undefined, y: undefined });

  const handleMouseOverAndOut: MouseEventHandler<HTMLAnchorElement> = ({ clientX, clientY, currentTarget }) => {
    const { left, top } = currentTarget.getBoundingClientRect();

    setHoverAnimationData({
      x: Math.floor(clientX - left),
      y: Math.floor(clientY - top),
    });
  };

  const handleControlClickFactory =
    (clickHandler: MouseEventHandler<HTMLButtonElement>) => (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();
      ev.preventDefault();
      clickHandler(ev);
    };

  return (
    <Link
      to={data.href ?? ''}
      className={cn('card', {
        card_template: render === DesignCardRenderer.template,
        card_draft: render === DesignCardRenderer.draft,
        card_ready: render === DesignCardRenderer.ready,
      })}
      onMouseEnter={handleMouseOverAndOut}
      onMouseLeave={handleMouseOverAndOut}
      ref={rootRef}
      style={
        {
          '--left': hoverAnimationData.x ?? 0,
          '--top': hoverAnimationData.y ?? 0,
        } as CSSProperties
      }
      data-testid="design-card-root"
    >
      <div
        className={cn('card__meta-wrap', 'card__separator-top', {
          'card__meta-wrap_over-hover': !data.controls,
        })}
      >
        {data.meta && (
          <div className={cn('card__meta')} data-testid="design-card-meta">
            {data.meta.map(({ key, value }) => (
              <div className={cn('card__meta-row')} key={key}>
                <span>{key}</span>
                <span>{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={cn('card__hover-content')}>
        <div className={cn('card__hover-image')} />
        {data.controls && (
          <div className={cn('card__control-list-wrap')}>
            <div className={cn('card__control-list')} data-testid="design-card-control-list">
              {data.controls.map(({ label, clickHandler }) => (
                <button className={cn('card__control')} onClick={handleControlClickFactory(clickHandler)} key={label}>
                  {label}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className={cn('card__title-wrap')}>
        <div className={cn('card__title')} title={data.title}>
          {data.title}
        </div>
      </div>

      <div className={cn('card__assay-list-wrap')}>
        {data.assayList && (
          <IconsAssaySet
            assayList={data.assayList}
            color={iconColorKeyList[render]}
            size={55}
            border={iconBorderKeyList[render]}
            className={cn('card__assay-list')}
            hidePreprocessingMark
          />
        )}
      </div>
      <div className={cn('card__aside', { 'card__separator-left': render !== DesignCardRenderer.draft })}>
        {render === DesignCardRenderer.template && <div className={cn('card__template-sign')} />}
        {render === DesignCardRenderer.draft && isDraftDesignCardData(data) && (
          <div className={cn('card__draft-step')}>
            {data.steps.current}
            <Slash className={cn('card__draft-step-slash')} />
            {data.steps.total}
          </div>
        )}
        {render === DesignCardRenderer.ready && isReadyDesignCardData(data) && (
          <>
            <div className={cn('card__ready-step-count')}>{data.laneCount}</div>
            <div className={cn('card__ready-step-text')}>{data.laneCount === 1 ? 'Lane' : 'Lanes'}</div>
          </>
        )}
      </div>
    </Link>
  );
};

export default DesignCard;
