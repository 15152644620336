import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';

import { OpticsSettingsItem } from '@/graphql/API';

import { DecimalRegex } from '@/helpers/validator';
import { getWaveUiInfo } from '@/helpers/waveLengths';

import NumberInput from '@/components/common/NumberInput';
import ReagentCell from '@/components/runDesign/ReagentComponent/ReagentCell';
import RunDesignTable from '@/components/runDesign/RunDesignTable';
import styles from './GlobalView.module.scss';

const cn = classnames.bind(styles);

type TGlobalSettingRow = {
  settings: OpticsSettingsItem;
  isEditMode: boolean;
  updateSettings: (newOpticalSettings: OpticsSettingsItem) => void;
};

const GlobalSettingRow: FC<TGlobalSettingRow> = ({ settings, isEditMode, updateSettings }) => {
  const [intensity, setIntensity] = useState(settings.intensity ?? 0);
  const [exposure, setExposure] = useState(settings.exposure ?? 0);

  const handleChangeNumber = useCallback(
    (setState: Dispatch<SetStateAction<number>>) => (num: number) => {
      setState(num);
    },
    []
  );

  const waveInfo = useMemo(
    () => getWaveUiInfo(settings.excitationWavelength, settings.detectionWavelength),
    [settings.excitationWavelength, settings.detectionWavelength]
  );

  useEffect(() => {
    updateSettings({
      ...settings,
      exposure,
      intensity,
    });
  }, [intensity, exposure]);

  return (
    <RunDesignTable.Row>
      <RunDesignTable.Column className={cn('global-view__column')}>
        <ReagentCell isEx color={waveInfo.ex.color}>
          {waveInfo.ex.uiLabel}({settings.excitationWavelength})
        </ReagentCell>
      </RunDesignTable.Column>

      <RunDesignTable.Column className={cn('global-view__column')}>
        <ReagentCell color={waveInfo.em.color}>
          {waveInfo.em.uiLabel}({settings.detectionWavelength})
        </ReagentCell>
      </RunDesignTable.Column>
      <RunDesignTable.Column className={cn('global-view__column')}>
        {isEditMode ? (
          <NumberInput
            onChange={handleChangeNumber(setIntensity)}
            className={cn('global-view__input')}
            value={intensity}
            min={0}
            max={100}
            customRegex={DecimalRegex}
          />
        ) : (
          <span className={cn('number-field')}>{settings.intensity ?? 0}</span>
        )}
      </RunDesignTable.Column>
      <RunDesignTable.Column className={cn('global-view__column')}>
        {isEditMode ? (
          <NumberInput
            min={0}
            max={5000}
            onChange={handleChangeNumber(setExposure)}
            className={cn('global-view__input')}
            value={exposure}
            customRegex={DecimalRegex}
          />
        ) : (
          <span className={cn('number-field')}>{settings.exposure ?? 0}</span>
        )}
      </RunDesignTable.Column>
    </RunDesignTable.Row>
  );
};

export default GlobalSettingRow;
