import { FC, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import { EAnnotationType } from '@/store/services/annotation/endpoints/types';

import ReagentOption from '@/components/runDesign/ReagentComponent/ReagentOption';
import AddToTableButton from '@/components/runDesign/AddToTableButton';
import reagentStyles from '@/components/runDesign/ReagentComponent/Reagent.module.scss';

import ReagentComponent from '@/components/runDesign/ReagentComponent';
import { TRunDesignCellType } from '@/store/slices/experimentRunDesign';
import { Compound, Reagent } from '@/graphql/API';

import SampleInformationAsyncSelect from '../SampleInformationAsyncSelect';
import useAsyncSelectAnnotation from '../../../../hooks/useAsynSelectAnnotation';
import styles from '../../EditCellType.module.scss';

const cn = classNames.bind({ ...styles, ...reagentStyles });

type TEditCellTypePreTreatmentProps = {
  cellType?: Nullable<TRunDesignCellType>;
  consumable?: Nullable<Compound | Reagent>;
  laneIndex: number;
  onChangeReagent: (runDesignCardIndex: number, data: Nullable<Compound | Reagent>) => void;
};

const EditCellTypePreTreatment: FC<TEditCellTypePreTreatmentProps> = ({
  consumable,
  cellType,
  onChangeReagent,
  laneIndex,
}) => {
  const handleChange = useCallback(
    (data: Nullable<Compound | Reagent>) => {
      onChangeReagent(laneIndex, data);
    },
    [onChangeReagent, laneIndex]
  );

  const {
    selectRef,
    isEditSelectMode,
    handleChangeEditMode,
    handleSelectedNameChange,
    handleSetEditMode,
    loadOptions,
    noOptionsMessage,
    selectedValue,
  } = useAsyncSelectAnnotation<Compound | Reagent>({
    annotation: consumable,
    onChange: handleChange,
    annotationType: [EAnnotationType.reagent, EAnnotationType.compound],
    annotationLabelKey: 'name',
    annotationValueKey: 'id',
  });

  const onBlur = useCallback(() => {
    handleSetEditMode(false);
  }, [handleSetEditMode]);

  useEffect(() => {
    handleSetEditMode(false);
  }, [consumable]);

  if (!cellType) {
    return null;
  }

  if (!isEditSelectMode && !consumable) {
    return (
      <div
        className={SELECTABLE_CLASS_NAME}
        {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 2, dataType: 'preTreatment' })}
      >
        <AddToTableButton onClick={handleChangeEditMode} text="Add reagent" />
      </div>
    );
  }

  if (!isEditSelectMode && consumable) {
    return (
      <div
        className={cn('cell-content', 'pre-labeling__btn', SELECTABLE_CLASS_NAME)}
        {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 2, dataType: 'preTreatment' })}
      >
        <ReagentComponent onClick={handleChangeEditMode} consumable={consumable} />
      </div>
    );
  }

  return (
    <div
      className={cn('cell-content', SELECTABLE_CLASS_NAME)}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 2, dataType: 'preTreatment' })}
    >
      <SampleInformationAsyncSelect
        selectRef={selectRef}
        value={selectedValue}
        noOptionsMessage={noOptionsMessage}
        onChange={handleSelectedNameChange}
        loadOptions={loadOptions}
        components={{ Option: ReagentOption }}
        menuClassName={cn('select-menu')}
        onBlur={onBlur}
        isClearable
      />
    </div>
  );
};

export default EditCellTypePreTreatment;
