import { getColorWithLuminance } from './common';

type TWaveInfo = {
  min: number;
  max: number;
  label: string;
  uiColor: string;
  uiLabel: string;
};

const EX_WHITE_LUMINANCE = -0.007;
const EM_WHITE_LUMINANCE = 0.045;
const DEFAULT_EM_LUMINANCE = 0.065;

const getMinMax = (defaultValue: number, diffValue: number) => {
  const diff = Math.ceil(diffValue / 2);

  return { min: defaultValue - diff, max: defaultValue + diff };
};

const EmWaveInfo: Record<string, TWaveInfo> = {
  red: {
    ...getMinMax(700, 75),
    label: 'C5',
    uiColor: '--wave-red',
    uiLabel: 'Red',
  },
  orange: {
    ...getMinMax(605, 52),
    label: 'C3',
    uiColor: '--wave-orange',
    uiLabel: 'Orange',
  },
  green: {
    ...getMinMax(535, 50),
    label: 'FT',
    uiColor: '--wave-green',
    uiLabel: 'Green',
  },
  violet: {
    ...getMinMax(430, 30),
    label: 'BV',
    uiColor: '--wave-violet',
    uiLabel: 'Violet',
  },
  multi: {
    min: Infinity,
    max: -Infinity,
    label: 'MB',
    uiColor: '--wave-multi',
    uiLabel: 'Multi',
  },
} as const;

export const ExWaveInfo: Record<string, TWaveInfo & { emList: TWaveInfo[] }> = {
  red: {
    ...getMinMax(635, 20),
    label: 'R',
    uiColor: '--wave-red',
    emList: [EmWaveInfo.red, EmWaveInfo.multi],
    uiLabel: 'Red',
  },
  green: {
    ...getMinMax(555, 25),
    label: 'G',
    uiColor: '--wave-green',
    emList: [EmWaveInfo.red, EmWaveInfo.orange, EmWaveInfo.multi],
    uiLabel: 'Green',
  },
  blue: {
    ...getMinMax(480, 30),
    label: 'B',
    uiColor: '--wave-blue',
    emList: [EmWaveInfo.red, EmWaveInfo.orange, EmWaveInfo.multi, EmWaveInfo.green],
    uiLabel: 'Blue',
  },
  violet: {
    ...getMinMax(405, 10),
    label: 'V',
    uiColor: '--wave-violet',
    emList: [EmWaveInfo.violet],
    uiLabel: 'Violet',
  },
};

const isNumberInWaveInterval = (value: number, minValue: number, maxValue: number) =>
  value < maxValue && value >= minValue;

const checkIsMulti = (values: number[]) => {
  const multiIntervals = [getMinMax(435, 25), getMinMax(525, 25), getMinMax(600, 40), getMinMax(765, 190)];
  return values.some((value) =>
    multiIntervals.some((interval) => isNumberInWaveInterval(value, interval.min, interval.max))
  );
};

type TReturnInfo = {
  em: { color: string; label: string; uiLabel: string };
  ex: { color: string; label: string; uiLabel: string };
};

export const getWaveUiInfo = (exValue: number, emValue: number | number[]): TReturnInfo => {
  const whiteColor = getComputedStyle(document.documentElement).getPropertyValue('--wave-white');
  const emInfo: TReturnInfo = {
    em: { color: getColorWithLuminance(whiteColor, EM_WHITE_LUMINANCE), label: 'BF', uiLabel: 'White' },
    ex: { color: getColorWithLuminance(whiteColor, EX_WHITE_LUMINANCE), label: 'BF', uiLabel: 'White' },
  };

  const exIntervalData = Object.values(ExWaveInfo).find((interval) =>
    isNumberInWaveInterval(exValue, interval.min, interval.max)
  );
  if (!exIntervalData) {
    return emInfo;
  }
  emInfo.ex.color = exIntervalData.uiColor;
  emInfo.ex.color = `var(${exIntervalData.uiColor})`;
  emInfo.ex.label = exIntervalData.label;
  emInfo.ex.uiLabel = exIntervalData.uiLabel;

  const emIntervalData =
    Array.isArray(emValue) && exIntervalData.emList.find((el) => el.label === 'MB') && checkIsMulti(emValue)
      ? EmWaveInfo.multi
      : exIntervalData.emList.find((interval) => isNumberInWaveInterval(Number(emValue), interval.min, interval.max));

  if (!emIntervalData) {
    return emInfo;
  }

  const emColorHex = getComputedStyle(document.documentElement).getPropertyValue(emIntervalData.uiColor);

  emInfo.em.color = getColorWithLuminance(emColorHex, DEFAULT_EM_LUMINANCE);
  emInfo.em.label = emIntervalData.label;
  emInfo.em.uiLabel = emIntervalData.uiLabel;

  return emInfo;
};
