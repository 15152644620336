import { formatDate, MDASH } from '@/helpers';

import { ERunDesignSourceType } from '@/types/experimentRunDesign';

import { runDesignNavigation, urlPostfixList } from '@/helpers/runDesigns/constants';
import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import { RunDesign, RunDesignTemplate } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

const isEveryComponentHasLanesConsumables = (componentList: TRunDesignComponent[], lanesCount: number) =>
  componentList.every(
    (component) =>
      component.performedOnLanes?.length === lanesCount &&
      component.performedOnLanes.every((lane) => !!lane.consumables?.length)
  );

// todo: Improve calculation of current step
export const getRunDesignSteps = (edgeNode: RunDesign) => {
  let currentStep = 0;
  const componentsCount = edgeNode.schema.components?.length ?? 0;
  const lanesCount = edgeNode.schema.lanes?.length ?? 0;

  if (componentsCount) {
    currentStep = 1;
  }

  if (currentStep === 1 && lanesCount) {
    currentStep = 2;
  }

  const { incubationComponentList = [], regularComponentList = [] } = Object.groupBy(
    edgeNode.schema.components ?? [],
    (component) => (isIncubationComponent(component) ? 'incubationComponentList' : 'regularComponentList')
  );

  if (currentStep === 2 && isEveryComponentHasLanesConsumables(regularComponentList, lanesCount)) {
    currentStep = 3;
  }

  if (
    currentStep === 3 &&
    incubationComponentList.length &&
    isEveryComponentHasLanesConsumables(incubationComponentList, lanesCount)
  ) {
    currentStep = 4;
  }

  return {
    total: 6,
    current: currentStep,
  };
};

export const getDraftHref = (runDesignId: string, currentStep: number) => {
  const stepIndex = currentStep > 1 ? currentStep - 1 : 0;
  const { step } = runDesignNavigation[stepIndex] ?? {};
  if (step) {
    return `/design-experiment/${ERunDesignSourceType.draft}/${runDesignId}/${urlPostfixList[step]}`;
  }
  return `/design-experiment/${ERunDesignSourceType.draft}/${runDesignId}`;
};

export const getRunDesignAssayList = (edgeNode: RunDesign) => {
  const assaysMap: Record<string, TAssay> = {
    SurfaceReceptor: { icon: '', id: '1', name: 'Surface receptor', properties: { active: true } },
    SurfaceReceptorCytokineSecretion: {
      icon: '',
      id: '1',
      name: 'Surface receptor Cytokine secretion',
      properties: { active: true },
    },
    CytokineSecretion: { icon: '', id: '2', name: 'Cytokine secretion', properties: { active: true } },
    CellKilling: { icon: '', id: '3', name: 'Cell killing', properties: { active: true } },
    mRNA: { icon: '', id: '4', name: 'mRNA expression', properties: { active: true } },
  };
  const componentList = edgeNode.schema.components ?? [];
  return componentList.reduce((list: TAssay[], component: TRunDesignComponent) => {
    const assay = assaysMap[component.type];
    if (assay) {
      list.push(assay);
    }
    return list;
  }, [] as TAssay[]);
};

export const getRunDesignMeta = (edgeNode: RunDesign, userList: TUser[]) => [
  {
    key: 'Author',
    value: userList.find((user) => user.username === edgeNode.investigatorId)?.displayName ?? MDASH,
  },
  { key: 'Created', value: formatDate(edgeNode.createdAt) ?? MDASH },
];

export const getTemplateMeta = (edgeNode: RunDesignTemplate, userList: TUser[]) => [
  {
    key: 'Author',
    value: userList.find((user) => user.username === edgeNode.authorId)?.displayName ?? MDASH,
  },
  { key: 'Created', value: formatDate(edgeNode.createdAt) ?? MDASH },
];
