import { FC, useCallback, useMemo, MouseEvent } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignCard from '@/components/runDesign/RunDesignCard';
import AddInformation from '@/components/runDesign/AddInformation';

import styles from './SampleTypeCard.module.scss';
import EditCellType from '../EditCellType';
import MinimizedCellType from '../MinimizedCellType';

const cn = classnames.bind(styles);

type TSampleTypeCard = {
  className?: string;
  runDesignCardIndex: number;
  isExpandMode?: boolean;
  isEditMode?: boolean;
  handleExpandMode: (index: number) => void;
  handleEditMode: (index: number) => void;
  showFunctionBlock?: boolean;
};

const SampleTypeCard: FC<TSampleTypeCard> = ({
  className,
  runDesignCardIndex,
  handleExpandMode,
  handleEditMode,
  isExpandMode,
  isEditMode,
  showFunctionBlock,
}) => {
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const hasCellTypes = useMemo(
    () => currentEditFields.schema?.lanes?.find((lane) => !!lane.cellTypes?.[runDesignCardIndex]),
    [currentEditFields.schema?.lanes, runDesignCardIndex]
  );

  const changeEditMode = useCallback(() => {
    handleEditMode?.(runDesignCardIndex);
  }, [handleEditMode, runDesignCardIndex]);

  const changeExpandMode = useCallback(() => {
    handleExpandMode?.(runDesignCardIndex);
  }, [handleExpandMode, runDesignCardIndex]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleEditMode?.(runDesignCardIndex);
    },
    [isEditMode, runDesignCardIndex]
  );

  return (
    <RunDesignCard className={cn(className)}>
      <RunDesignCard.Header
        isExpandMode={isExpandMode}
        isEditMode={isEditMode}
        onExpandClick={changeExpandMode}
        onSettingClick={changeEditMode}
      >
        <span>Sample cell type {runDesignCardIndex + 1}</span>
      </RunDesignCard.Header>
      <RunDesignCard.Content className={cn('sample-type-card__wrapper')} onClick={handleCardContentClick}>
        <div className={cn('sample-type-card__content')}>
          {isEditMode && <EditCellType runDesignCardIndex={runDesignCardIndex} showFunctionBlock={showFunctionBlock} />}
          {!isEditMode && !hasCellTypes && <AddInformation onClick={changeEditMode} />}
          {!isEditMode && hasCellTypes && (
            <MinimizedCellType
              runDesignCardIndex={runDesignCardIndex}
              isExpandMode={isExpandMode}
              showFunctionBlock={showFunctionBlock}
            />
          )}
        </div>
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default SampleTypeCard;
