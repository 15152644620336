import { Dispatch, FC, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { arrToMapByKeys } from '@/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import {
  experimentRunDesignActions,
  experimentRunDesignSelectors,
  TRunDesignConsumableToUse,
} from '@/store/slices/experimentRunDesign';

import { RunDesignContext } from '@/pages/experiment-run-design/context';

import RunDesignTable from '@/components/runDesign/RunDesignTable';
import ReagentHeader from '@/components/runDesign/ReagentComponent/ReagentHeader';

import type { TReagentListByLaneList } from '../types';
import styles from '../ReagentsCard.module.scss';
import ReagentsRow from './ReagentsRow';

const cn = classnames.bind(styles);

type TEditReagents = {
  isEdit?: boolean;
  withWaves?: boolean;
  currentReagentListByLaneList: TReagentListByLaneList;
  setCurrentReagentListByLaneList: Dispatch<SetStateAction<TReagentListByLaneList>>;
};

const EditReagents: FC<TEditReagents> = ({
  isEdit,
  withWaves,
  currentReagentListByLaneList,
  setCurrentReagentListByLaneList,
}) => {
  const appDispatch = useAppDispatch();
  const currentLanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);

  const { overrideCustomFooterConfig } = useContext(RunDesignContext);

  const [tableHeaders, setTableHeaders] = useState<ReactNode[]>([]);

  const lanesMap = useMemo(() => arrToMapByKeys(currentLanes ?? [], 'id'), [currentLanes]);

  const formattedTableData = useMemo(
    () => currentReagentListByLaneList.map((el) => el.reagents),
    [currentReagentListByLaneList]
  );

  const updateTableData = useCallback((newData: any[]) => {
    setCurrentReagentListByLaneList((prev) => {
      const copyPrev = structuredClone(prev);
      copyPrev.forEach((laneData, laneIndex) => {
        laneData.reagents = newData[laneIndex];
      });

      return copyPrev;
    });
  }, []);

  useEffect(() => {
    if (!overrideCustomFooterConfig) {
      return;
    }

    overrideCustomFooterConfig({
      saveAndContinue: {
        clickHandler: () => {
          appDispatch(experimentRunDesignActions.updateComponentsLanesReagents(currentReagentListByLaneList));
          appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
        },
      },
    });
  }, [overrideCustomFooterConfig, currentReagentListByLaneList]);

  useEffect(() => {
    const countOfRepeated = Math.max(
      ...Object.values(currentReagentListByLaneList).map(
        (reagentListData) =>
          (isEdit ? 1 : 0) + 1 + reagentListData.reagents.findLastIndex((reagent) => !!reagent?.consumable.id)
      )
    );
    setTableHeaders(
      Array.from({ length: countOfRepeated }, (_, index) => {
        const key = index;
        const showWavesInHeader = currentReagentListByLaneList.some(
          (laneData) => !!laneData.reagents?.[index]?.consumable
        );
        return (
          <ReagentHeader key={key} withWaves={showWavesInHeader}>
            REAGENT {index + 1}
          </ReagentHeader>
        );
      })
    );
  }, [currentReagentListByLaneList, isEdit]);

  return (
    <RunDesignTable<Array<TRunDesignConsumableToUse | null>[]>
      className={cn('reagents-table')}
      tableData={formattedTableData}
      setTableData={updateTableData}
      isEdit={isEdit}
      header={
        <RunDesignTable.Row>
          {tableHeaders.map((header, headerIndex) => {
            const key = headerIndex;
            return <RunDesignTable.Column key={key}>{header}</RunDesignTable.Column>;
          })}
        </RunDesignTable.Row>
      }
    >
      {currentReagentListByLaneList.map((reagentListByLane, rowIndex) => {
        const laneKey = reagentListByLane.laneLetter;

        if (!lanesMap[laneKey]) {
          return <RunDesignTable.Row key={laneKey} />;
        }

        return (
          <ReagentsRow
            updateCurrentReagentList={setCurrentReagentListByLaneList}
            reagentListByLane={reagentListByLane}
            isEdit={isEdit}
            allReagentsData={currentReagentListByLaneList}
            key={laneKey}
            rowIndex={rowIndex}
            withWaves={withWaves}
          />
        );
      })}
    </RunDesignTable>
  );
};

export default EditReagents;
