import { FC } from 'react';
import classNames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { CellFunction } from '@/graphql/API';
import { TRunDesignCellType } from '@/store/slices/experimentRunDesign';

import { addSelectableAttribute, SELECTABLE_CLASS_NAME } from '@/helpers/reactSelectable';

import Select, { TSelectProps } from '@/components/common/Select';

import styles from '../../EditCellType.module.scss';

const cn = classNames.bind(styles);

type TEditCellTypeFunctionProps = {
  cellType?: Nullable<TRunDesignCellType>;
  laneIndex: number;
  onChangeFunction: (runDesignCardIndex: number, newFunction: CellFunction) => void;
  displayIfEmpty?: boolean;
} & Pick<TSelectProps, 'className' | 'menuClassName' | 'controlClassName' | 'optionClassName'>;

const EditCellTypeFunction: FC<TEditCellTypeFunctionProps> = ({
  cellType,
  laneIndex,
  onChangeFunction,
  className,
  menuClassName,
  controlClassName,
  optionClassName,
  displayIfEmpty,
}) => {
  const optionList = [
    { label: 'Effector', value: CellFunction.EFFECTOR },
    { label: 'Target', value: CellFunction.TARGET },
  ];

  const handleFunctionChange = (newFunction: CellFunction) => {
    onChangeFunction(laneIndex, newFunction);
  };

  if (!cellType && !displayIfEmpty) {
    return;
  }

  return (
    <div
      className={SELECTABLE_CLASS_NAME}
      {...addSelectableAttribute({ rowIndex: laneIndex, columnIndex: 1, dataType: 'function' })}
    >
      <Select
        value={cellType?.function ?? ''}
        options={optionList}
        onChange={handleFunctionChange}
        className={cn('edit-cell-type__select', className)}
        controlClassName={cn('edit-cell-type__select-control', controlClassName)}
        menuClassName={cn('edit-cell-type__select-menu', menuClassName)}
        optionClassName={cn('edit-cell-type__option', optionClassName)}
        theme={themeOptions.light}
      />
    </div>
  );
};

export default EditCellTypeFunction;
