import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EDesignStep } from '@/pages/experiment-run-design/types';

import { runDesignNavigation, runDesignStepOrder, urlPostfixList } from '@/helpers/runDesigns/constants';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { useEditDesignMethods } from '@/pages/experiment-run-design/hooks/useEditDesignMethods';
import { useRunDesignLocation } from '@/pages/experiment-run-design/hooks/useRunDesignLocation';

import { graphqlAPI } from '@/store/services/graphql';
import { experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import { TDefaultPageControlsConfig } from '@/components/common/PagePanel/PageControls/types';

const enum ESavingButton {
  save = 'save',
  next = 'next',
}

export const usePageControlsConfig = (
  onValidationErrors: (errors: Record<string, string>) => void,
  openCompleteModal?: () => void
) => {
  const navigate = useNavigate();
  const confirmationModal = useConfirmationModalContext();

  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const isEditFieldsHaveChanges = useSelector(experimentRunDesignSelectors.selectIsEditFieldsHaveChanges);

  const [createRunDesign, { isLoading: isCreatingRunDesign }] = graphqlAPI.useCreateRunDesignMutation();
  const [updateRunDesign, { isLoading: isUpdatingRunDesign }] = graphqlAPI.useUpdateRunDesignMutation();
  const [publishRunDesign, { isLoading: isPublishingRunDesign }] = graphqlAPI.usePublishRunDesignMutation();

  const { isDraft, getUpdateInputAndErrors, getCreateInputAndErrors } = useEditDesignMethods();
  const { goToPrevStep, goToNextStep, goToStep, currentStep } = useRunDesignLocation();

  const [savingButton, setSavingButton] = useState('');

  const isSavingRunDesign = useMemo(
    () => isCreatingRunDesign || isUpdatingRunDesign || isPublishingRunDesign,
    [isCreatingRunDesign, isUpdatingRunDesign, isPublishingRunDesign]
  );

  const isLastStep = useMemo(() => currentStep === runDesignStepOrder[runDesignStepOrder.length - 1], [currentStep]);

  const navigateToDraftList = useCallback(() => {
    navigate(isDraft ? '/design-experiment?tab=drafts' : '/design-experiment');
  }, [isDraft]);

  const handleBackButtonClick = useCallback(async () => {
    if (currentStep !== EDesignStep.details) {
      goToPrevStep();
      return;
    }

    if (isEditFieldsHaveChanges) {
      const isConfirmed = await confirmationModal.onOpen({
        confirmationText: 'Changes you made are not saved.',
        approveButtonText: 'Discard changes and continue',
      });
      if (!isConfirmed) {
        return;
      }
    }

    navigateToDraftList();
  }, [currentStep, goToPrevStep, isEditFieldsHaveChanges, navigate]);

  const handleCreateRunDesign = useCallback(
    (successCallback: (createdRunDesignId?: string) => void) => {
      const { input, validationErrors } = getCreateInputAndErrors();
      if (Object.keys(validationErrors).length > 0) {
        onValidationErrors(validationErrors);
        return;
      }
      createRunDesign(input).then((result) => {
        if ('error' in result) {
          showErrorToast(getErrorMessage(result.error));
        } else {
          successCallback(result?.data);
        }
      });
    },
    [getCreateInputAndErrors, onValidationErrors, createRunDesign]
  );

  const handleUpdateRunDesign = useCallback(
    (successCallback: () => void) => {
      if (!designData.runDesignId) {
        showErrorToast('No run design identifier found');
        return;
      }
      const { input, validationErrors } = getUpdateInputAndErrors();
      if (Object.keys(validationErrors).length > 0) {
        onValidationErrors(validationErrors);
        return;
      }
      updateRunDesign({ id: designData.runDesignId, input }).then((result) => {
        if ('error' in result) {
          showErrorToast(getErrorMessage(result.error));
        } else {
          successCallback();
        }
      });
    },
    [designData.runDesignId, getUpdateInputAndErrors, onValidationErrors, updateRunDesign]
  );

  const handleSaveButtonClick = useCallback(() => {
    setSavingButton(ESavingButton.save);
    if (isDraft) {
      handleUpdateRunDesign(navigateToDraftList);
    } else {
      handleCreateRunDesign(navigateToDraftList);
    }
  }, [isDraft, handleUpdateRunDesign, handleCreateRunDesign, navigateToDraftList]);

  const publishRunDesignAfterSaving = useCallback(() => {
    if (!designData.runDesignId) {
      showErrorToast('No run design identifier found');
      return;
    }
    publishRunDesign(designData.runDesignId).then((publishResult) => {
      if ('error' in publishResult) {
        showErrorToast(getErrorMessage(publishResult.error));
        return;
      }
      openCompleteModal?.();
    });
  }, [designData.runDesignId, publishRunDesign, openCompleteModal]);

  const handleNextButtonClick = useCallback(() => {
    setSavingButton(ESavingButton.next);
    if (isDraft) {
      if (isEditFieldsHaveChanges) {
        handleUpdateRunDesign(isLastStep ? publishRunDesignAfterSaving : goToNextStep);
      } else if (isLastStep) {
        publishRunDesignAfterSaving();
      } else {
        goToNextStep();
      }
    } else {
      handleCreateRunDesign((createdRunDesignId?: string) => {
        navigate(`/design-experiment/draft/${createdRunDesignId}/${urlPostfixList[EDesignStep.timeline]}`);
      });
    }
  }, [
    isDraft,
    isEditFieldsHaveChanges,
    isLastStep,
    handleUpdateRunDesign,
    publishRunDesignAfterSaving,
    goToNextStep,
    handleCreateRunDesign,
    navigate,
  ]);

  const pageControlsConfig: TDefaultPageControlsConfig = useMemo(
    () => ({
      back: {
        clickHandler: handleBackButtonClick,
        disabled: isSavingRunDesign,
      },
      save: {
        clickHandler: handleSaveButtonClick,
        disabled: isSavingRunDesign,
        isLoading: isSavingRunDesign && savingButton === ESavingButton.save,
      },
      next: {
        customTitle: isLastStep ? 'Ready' : undefined,
        clickHandler: handleNextButtonClick,
        disabled: isSavingRunDesign,
        isLoading: isSavingRunDesign && savingButton === ESavingButton.next,
      },
      navigation:
        currentStep !== EDesignStep.details
          ? runDesignNavigation.map(({ step, label }) => ({
              step,
              label,
              clickHandler: () => {
                if (!isEditFieldsHaveChanges) {
                  goToStep(step);
                  return;
                }
                const successCallback = () => goToStep(step);
                handleUpdateRunDesign(successCallback);
              },
              isCurrent: step === currentStep,
              disabled: isSavingRunDesign,
            }))
          : undefined,
    }),
    [
      handleBackButtonClick,
      handleSaveButtonClick,
      isLastStep,
      handleNextButtonClick,
      currentStep,
      runDesignNavigation,
      goToStep,
      isSavingRunDesign,
      savingButton,
      isEditFieldsHaveChanges,
    ]
  );

  useEffect(() => {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = true;
    };

    if (isEditFieldsHaveChanges) {
      window.addEventListener('beforeunload', onBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [isEditFieldsHaveChanges]);

  return useMemo(
    () => ({
      pageControlsConfig,
      saveRunDesignAndExit: handleSaveButtonClick,
      handleUpdateRunDesign,
      isSavingRunDesign,
    }),
    [pageControlsConfig, handleSaveButtonClick, handleUpdateRunDesign, isSavingRunDesign]
  );
};
