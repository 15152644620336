import { CSSProperties, FC, useMemo } from 'react';
import classNames from 'classnames/bind';

import { getComponentPosition, getScanLabelsMap } from '@/helpers/runDesigns/timeline';
import { isScanComponent } from '@/helpers/runDesigns/typeGuards';

import { Placement } from '@/graphql/API';

import { TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import icons from '@/components/common/icons';

import SchemaComponent from '../SchemaComponent';

import styles from './ComponentSelector.module.scss';

const cn = classNames.bind(styles);

type TComponentSelectorProps = {
  componentList: TRunDesignComponent[];
  currentComponentId: string;
  onComponentClickFactory: (id: string) => () => void;
  onAddIncubationClick: () => void;
};

const ComponentSelector: FC<TComponentSelectorProps> = ({
  componentList,
  currentComponentId,
  onComponentClickFactory,
  onAddIncubationClick,
}) => {
  const scanLabelsMap = useMemo(() => getScanLabelsMap(componentList ?? []), [componentList]);

  const timelineComponentList = useMemo(
    () => componentList?.filter((component) => !isScanComponent(component)) ?? [],
    [componentList]
  );

  const renderingComponentList = useMemo(() => {
    const firstRowComponentList = timelineComponentList.filter(
      (component) => component.timing.placement !== Placement.SIMULTANEOUS
    );
    let simultaneousRowIndex = 2;

    return timelineComponentList.map((component) => {
      let rowIndex = 1;
      if (component.timing.placement === Placement.SIMULTANEOUS) {
        rowIndex = simultaneousRowIndex;
        simultaneousRowIndex += 1;
      }

      return {
        component,
        position: getComponentPosition(firstRowComponentList, component, rowIndex),
      };
    });
  }, [timelineComponentList]);

  const firstRowRunsCount = useMemo(
    () => timelineComponentList.filter((component) => component.timing.placement !== Placement.SIMULTANEOUS).length,
    [timelineComponentList]
  );

  return (
    <div className={cn('component-selector')}>
      <div
        className={cn('component-selector__table')}
        style={{ '--column-count': Math.max(1, firstRowRunsCount) } as CSSProperties}
      >
        {firstRowRunsCount === 0 && <div />}
        {renderingComponentList.map(({ component, position }) => (
          <SchemaComponent
            label={component.name}
            scanLabel={scanLabelsMap[component.id]}
            key={component.id}
            isCurrent={currentComponentId === component.id}
            onClick={onComponentClickFactory(component.id)}
            position={position}
          />
        ))}
      </div>
      <div className={cn('component-selector__add-incubation-wrap')}>
        <button className={cn('component-selector__add-incubation')} onClick={onAddIncubationClick}>
          <icons.PlusIcon className={cn('component-selector__add-incubation-icon')} />
          Add Incubation
        </button>
      </div>
    </div>
  );
};

export default ComponentSelector;
