import { CSSProperties, FC, ReactNode } from 'react';
import classnames from 'classnames/bind';

import { addTooltip } from '@/helpers';
import styles from './Column.module.scss';

const cn = classnames.bind(styles);

type TColumn = {
  className?: string;
  children?: ReactNode;
  tooltipMessage?: string;
  style?: CSSProperties;
};

const Column: FC<TColumn> = ({ children, className, tooltipMessage, style }) => (
  <td style={style} className={cn('column', className)} {...addTooltip(tooltipMessage)}>
    {children}
  </td>
);

export default Column;
