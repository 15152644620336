import { SubCompIncubation, TemperatureUnit } from '@/graphql/API';

const getInitialIncubation = (): SubCompIncubation => ({
  __typename: 'SubCompIncubation',
  duration: 0,
  temperature: 0,
  temperatureUnit: TemperatureUnit.C,
  onDevice: false,
});

export default getInitialIncubation;
