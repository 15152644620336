import { FC, useCallback } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import styles from './AddInformation.module.scss';

const cn = classnames.bind(styles);

type TAddInformation = {
  onClick: () => void;
};

const AddInformation: FC<TAddInformation> = ({ onClick }) => {
  const handleClick = useCallback(() => onClick(), [onClick]);

  return (
    <div className={cn('add-information__wrapper')}>
      <button onClick={handleClick} className={cn('add-information__btn')}>
        <div className={cn('add-information__content')}>
          <icons.BigPlus height={70} width={70} />
          <span>Add Information</span>
        </div>
      </button>
    </div>
  );
};

export default AddInformation;
