import { FC, MutableRefObject, useCallback, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import NoDataFound from '@/components/common/NoDataFound';
import RunDesignCard from '@/components/runDesign/RunDesignCard';

import styles from './SettingsCard.module.scss';
import MatrixView from '../MatrixView';
import GlobalView from '../GlobalView';

const cn = classnames.bind(styles);

type TSettingsCard = {
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

const SettingsCard: FC<TSettingsCard> = ({ innerRef }) => {
  const appDispatch = useAppDispatch();
  const currentLanes = useSelector(experimentRunDesignSelectors.selectCurrentLanes);
  const isEditMode = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);
  const isGlobalView = useSelector(experimentRunDesignSelectors.selectIsGlobalSettingsView);

  const handleSettingClick = useCallback(() => {
    appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(isEditMode ? -1 : 0));
    appDispatch(experimentRunDesignActions.setIsGlobalSettingsView(true));
  }, [isEditMode]);

  if (!currentLanes?.length || currentLanes.some((lane) => !lane.cellTypes)) {
    return <NoDataFound size="big" textData="There are no optic settings" className="center-block" />;
  }

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleSettingClick();
    },
    [isEditMode, handleSettingClick]
  );

  return (
    <RunDesignCard cardRef={innerRef}>
      <RunDesignCard.Header onSettingClick={handleSettingClick} isEditMode={isEditMode}>
        <span>Optical Settings</span>
      </RunDesignCard.Header>
      <RunDesignCard.Content className={cn('content')} onClick={handleCardContentClick}>
        {!isEditMode && <GlobalView isEditMode={isEditMode} />}

        {isEditMode && isGlobalView && <GlobalView isEditMode={isEditMode} />}
        {isEditMode && !isGlobalView && <MatrixView />}
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default SettingsCard;
