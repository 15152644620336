import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import RunDesignBlockWithLanes from '@/components/runDesign/RunDesignBlockWithLanes';
import NoDataFound from '@/components/common/NoDataFound';

import SampleTypesBlock from './components/SampleTypesBlock';

const SampleInformation: FC = () => {
  const appDispatch = useAppDispatch();

  const cellTypesCount = useSelector(experimentRunDesignSelectors.selectCountOfCellTypes);

  useEffect(
    () => () => {
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexEdit(-1));
      appDispatch(experimentRunDesignActions.setRunDesignCardIndexExpand(-1));
    },
    []
  );

  return (
    <RunDesignBlockWithLanes title="Enter sample information">
      {cellTypesCount === 0 ? <NoDataFound size="big" textData="There are no cell types" /> : <SampleTypesBlock />}
    </RunDesignBlockWithLanes>
  );
};

export default SampleInformation;
