import { FC, useCallback, useMemo, MouseEvent, useState, useEffect } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { arrToMapByKeys, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isIncubationComponent } from '@/helpers/runDesigns/typeGuards';

import { experimentRunDesignSelectors, TRunDesignComponent } from '@/store/slices/experimentRunDesign';

import AddInformation from '@/components/runDesign/AddInformation';
import RunDesignCard from '@/components/runDesign/RunDesignCard';

import { TReagentListByLaneList } from './types';
import { getDurationText, getTemperatureText } from './helpers';

import EditReagents from './components/EditReagents';

import styles from './ReagentsCard.module.scss';

const cn = classnames.bind(styles);

type TReagentsCard = {
  className?: string;
  component: TRunDesignComponent;
  handleExpandMode: (index: number) => void;
  handleEditMode: (index: number) => void;
  runDesignCardIndex: number;
  isExpandMode?: boolean;
  isEditMode?: boolean;
  withWaves?: boolean;
  openSettingsModal?: (id: string) => void;
};

const ReagentsCard: FC<TReagentsCard> = ({
  className,
  component,
  runDesignCardIndex,
  handleEditMode,
  handleExpandMode,
  isEditMode,
  isExpandMode,
  withWaves,
  openSettingsModal,
}) => {
  const someRunDesignCardIsEdit = useSelector(experimentRunDesignSelectors.selectSomeRunDesignCardIsEdit);

  const savedReagentListByLaneList = useMemo<TReagentListByLaneList>(() => {
    const byLanesMap = arrToMapByKeys(component?.performedOnLanes ?? [], 'laneId');

    const usedReagentList: TReagentListByLaneList = LANE_LETTER_NAME_LIST.map((laneLetter) => {
      if (!byLanesMap[laneLetter]?.consumables) {
        return {
          componentId: component?.id ?? '',
          laneLetter,
          reagents: [],
        };
      }

      return {
        componentId: component?.id ?? '',
        laneLetter,
        reagents: byLanesMap[laneLetter]?.consumables ?? [],
      };
    });

    return usedReagentList;
  }, [component]);

  const [currentReagentListByLaneList, setCurrentReagentListByLaneList] = useState<TReagentListByLaneList>(
    structuredClone(savedReagentListByLaneList)
  );

  const countReagentColumns = useMemo(() => {
    if (currentReagentListByLaneList.length === 0) {
      return 0;
    }
    return Math.max(
      ...currentReagentListByLaneList.map((reagentListByLane) => reagentListByLane?.reagents?.length ?? 0)
    );
  }, [currentReagentListByLaneList]);

  const shouldDisplayAddInformation = useMemo(
    () => !isEditMode && component?.performedOnLanes.every((el) => !el.consumables?.length),
    [isEditMode, component?.performedOnLanes]
  );

  const isIncubation = useMemo(() => isIncubationComponent(component), [component?.type]);

  const incubationData = useMemo(() => {
    if (isIncubationComponent(component) && !!component.incubation) {
      const { onDevice, temperature, temperatureUnit, duration } = component.incubation;
      return {
        deviceText: onDevice ? 'On-device' : 'Off-device',
        temperature: getTemperatureText(temperature, temperatureUnit),
        duration: getDurationText(duration),
      };
    }
    return null;
  }, [component]);

  const changeEditMode = useCallback(() => {
    handleEditMode?.(runDesignCardIndex);
  }, [handleEditMode, runDesignCardIndex]);

  const changeExpandMode = useCallback(() => {
    handleExpandMode?.(runDesignCardIndex);
  }, [handleExpandMode, runDesignCardIndex]);

  const handleIncubationDataClick = useCallback(() => {
    if (!component.id || !openSettingsModal) return;

    openSettingsModal(component.id);
  }, [openSettingsModal, component.id]);

  const handleCardContentClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isEditMode) return;
      handleEditMode?.(runDesignCardIndex);
    },
    [isEditMode, runDesignCardIndex]
  );

  useEffect(() => {
    setCurrentReagentListByLaneList(structuredClone(savedReagentListByLaneList));
  }, [savedReagentListByLaneList, someRunDesignCardIsEdit]);

  return (
    <RunDesignCard className={cn(className)}>
      <RunDesignCard.Header
        isExpandMode={isExpandMode}
        onExpandClick={changeExpandMode}
        onSettingClick={changeEditMode}
        isEditMode={isEditMode}
      >
        <div className={cn('assay-reagents__wrapper')}>
          <span className={cn('assay-reagents__name')}>{component?.name ?? 'Assay reagents'}</span>
          {isIncubation ? (
            incubationData && (
              <button className={cn('assay-reagents__modal-btn')} onClick={handleIncubationDataClick}>
                <span className={cn('assay-reagents__badge')}>{incubationData.deviceText}</span>
                <span className={cn('assay-reagents__badge')}>{incubationData.duration}</span>
                <span className={cn('assay-reagents__badge')}>{incubationData.temperature}</span>
              </button>
            )
          ) : (
            <span className={cn('assay-reagents__badge')}>{countReagentColumns}</span>
          )}
        </div>
      </RunDesignCard.Header>
      <RunDesignCard.Content onClick={handleCardContentClick} className={cn('content')}>
        {shouldDisplayAddInformation ? (
          <AddInformation onClick={changeEditMode} />
        ) : (
          <EditReagents
            isEdit={isEditMode}
            withWaves={withWaves}
            currentReagentListByLaneList={currentReagentListByLaneList}
            setCurrentReagentListByLaneList={setCurrentReagentListByLaneList}
          />
        )}
      </RunDesignCard.Content>
    </RunDesignCard>
  );
};

export default ReagentsCard;
