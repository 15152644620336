import { CSSProperties, FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { getWaveUiInfo } from '@/helpers/waveLengths';

import styles from './Reagent.module.scss';

const cn = classnames.bind(styles);

type TReagentWaves = {
  excitationWavelength: number;
  detectionWavelength: number;
  className?: string;
};

const ReagentWaves: FC<TReagentWaves> = ({ detectionWavelength, excitationWavelength, className }) => {
  const waveInfo = useMemo(
    () => getWaveUiInfo(excitationWavelength, detectionWavelength),
    [excitationWavelength, detectionWavelength]
  );

  return (
    <div className={cn('waves', className)}>
      <div className={cn('waves__wave-item', 'waves__ex')} style={{ '--ex-bg': waveInfo.ex.color } as CSSProperties}>
        {waveInfo.ex.label}
      </div>
      <div className={cn('waves__wave-item', 'waves__em')} style={{ '--em-bg': waveInfo.em.color } as CSSProperties}>
        {waveInfo.em.label}
      </div>
    </div>
  );
};

export default ReagentWaves;
