import { ChangeEvent, FormEvent, RefObject, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';

import { formatDate } from '@/helpers';
import { showErrorToast } from '@/helpers/errors';

import { useFetchNextToken } from '@/hooks';
import useItemList from '@/hooks/useItemList';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { authAPI } from '@/store/services/auth';
import { experimentRunDesignActions, experimentRunDesignSelectors } from '@/store/slices/experimentRunDesign';

import TextInput from '@/components/common/TextInput';
import Textarea from '@/components/common/Textarea';
import ProjectSelect from '@/components/admin/ProjectSelect';
import Select from '@/components/common/Select';
import PagePanel from '@/components/common/PagePanel';
import Metadata from '@/components/common/Metadata';
import NoDataFound from '@/components/common/NoDataFound';
import icons from '@/components/common/icons';

import { usePageControlsConfig } from '@/hooks/runDesign/usePageControlsConfig';
import { useEditDesignData } from '../hooks/useEditDesignData';

import PageContextProvider from './components/PageContextProvider';
import ExternalLinkItem from './components/ExternalLinkItem';
import OrganismItem from './components/OrganismItem';

import designExperimentStyles from '../DesignSelector/DesignSelector.module.scss';
import styles from './DesignDetails.module.scss';

const cn = classnames.bind({ ...designExperimentStyles, ...styles });

const DesignDetails = () => {
  const appDispatch = useAppDispatch();
  const [scrollRefState, setScrollRefState] = useState<Nullable<RefObject<HTMLDivElement>>>(null);

  const { isLoading, isError } = useEditDesignData();

  const designData = useSelector(experimentRunDesignSelectors.selectData);
  const currentEditFields = useSelector(experimentRunDesignSelectors.selectCurrentEditFields);

  const { list: userList } = useFetchNextToken<TUser>({
    useFetch: authAPI.useFetchUserListQuery,
    select: authAPI.endpoints.fetchUserList.select,
  });
  const userOptionList = useMemo(
    () => userList.map((user) => ({ label: user.displayName, value: user.username })),
    [userList]
  );

  const {
    list: organismList,
    addEmptyItem: addOrganism,
    changeItem: changeOrganism,
    deleteItem: deleteOrganism,
  } = useItemList<string>(
    currentEditFields.organisms ?? [],
    (valueList: string[]) => {
      appDispatch(experimentRunDesignActions.setEditFields({ organisms: valueList }));
    },
    '',
    (value) => !!value.trim()
  );
  const {
    list: externalLinkList,
    addEmptyItem: addExternalLink,
    changeItem: changeExternalLink,
    deleteItem: deleteExternalLink,
  } = useItemList<string>(
    currentEditFields.externalLinks ?? [],
    (valueList: string[]) => {
      appDispatch(experimentRunDesignActions.setEditFields({ externalLinks: valueList }));
    },
    '',
    (value) => !!value.trim()
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const { pageControlsConfig, saveRunDesignAndExit } = usePageControlsConfig(setErrors);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    saveRunDesignAndExit();
  };

  const deleteFieldError = (fieldName: string) => {
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[fieldName];
      return newErrors;
    });
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    appDispatch(experimentRunDesignActions.setEditFields({ name: event.target.value }));
    deleteFieldError('name');
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    appDispatch(experimentRunDesignActions.setEditFields({ description: event.target.value }));
  };

  const handleInvestigatorIdChange = (newInvestigatorId: string) => {
    appDispatch(experimentRunDesignActions.setEditFields({ investigatorId: newInvestigatorId ?? '' }));
    deleteFieldError('investigatorId');
  };

  const handleProjectIdChange = (newProjectId: string) => {
    appDispatch(experimentRunDesignActions.setEditFields({ projectId: newProjectId ?? '' }));
    deleteFieldError('projectId');
  };

  useEffect(() => {
    const errorFieldList = Object.keys(errors).filter(
      (field) => !['name', 'investigatorId', 'projectId'].includes(field)
    );
    if (errorFieldList.length > 0) {
      showErrorToast(`The following fields have incorrect values: ${errorFieldList.join(', ')}`);
    }
  }, [errors]);

  // wrapper for adding entity with scroll on demand
  const addEntityFactory = (addEntity: () => void) => () => {
    if (!scrollRefState?.current) {
      addEntity();
      return;
    }

    const heightBeforeAdd = scrollRefState.current.scrollHeight;
    addEntity();

    // delay for layout change by addEntity
    setTimeout(() => {
      if (!scrollRefState?.current) {
        return;
      }

      const heightAfterAdd = scrollRefState.current.scrollHeight;

      if (heightAfterAdd > heightBeforeAdd) {
        scrollRefState.current.scrollBy(0, heightAfterAdd - heightBeforeAdd);
      }
    }, 100);
  };

  return (
    <PagePanel
      title="Run details"
      isLoading={isLoading}
      className={cn('design-experiment', 'details')}
      config={pageControlsConfig}
    >
      <PageContextProvider setScrollRefState={setScrollRefState} />
      <PagePanel.CollapsableHeader className={cn('details__header')} collapsedClassName={cn('_collapsed')}>
        <div className={cn('details__metadata-wrap', { _loading: !designData.templateName })}>
          {designData.templateName && (
            <Metadata className={cn('details__metadata')}>
              <Metadata.Item
                title="Template"
                description={designData.templateName ?? <NoDataFound alignment="left" />}
                titleClassName={cn('details__metadata-title')}
                className={cn('details__metadata-item')}
              />
            </Metadata>
          )}
        </div>

        <div className={cn('design-experiment__header')}>Provide experiment details</div>
      </PagePanel.CollapsableHeader>

      {!isLoading && !isError && (
        <form onSubmit={handleFormSubmit} className={cn('design-experiment__content')}>
          <div className={cn('details__panel', 'details__panel_design-name')}>
            <div className={cn('details__group')}>
              <label htmlFor="design_name" className={cn('details__name-label')}>
                Run name <span className={cn('required')}>*</span>
              </label>
              <TextInput
                id="design_name"
                value={currentEditFields.name}
                onChange={handleNameChange}
                errorMessage={errors.name}
                className={cn('details__value', 'details__input', { details__input_error: errors.name })}
              />
            </div>
          </div>
          <div className={cn('details__panel')}>
            <div className={cn('details__group')}>
              <label htmlFor="design_description">Description</label>
              <Textarea
                placeholder="Objective or hypothesis of the run"
                id="design_description"
                value={currentEditFields.description ?? ''}
                onChange={handleDescriptionChange}
                wrapperClassName={cn('details__textarea-wrapper')}
                className={cn('details__value', 'details__textarea')}
                rows={6}
              />
              {designData.runDesignDate && (
                <>
                  <label htmlFor="design_date">Design date</label>
                  <TextInput
                    id="design_date"
                    value={formatDate(designData.runDesignDate, 'E, MMM d  h:mm a')}
                    disabled
                    className={cn('details__value', 'details__input')}
                  />
                </>
              )}
              <label htmlFor="design_link">External links</label>
              <div className={cn('details__multi-value')}>
                {externalLinkList.map((link, index) => (
                  <ExternalLinkItem
                    key={link.uuid}
                    value={link.value}
                    uuid={link.uuid}
                    onChange={changeExternalLink}
                    inputId={index === 0 ? 'design_link' : undefined}
                    isDeleteAllowed={externalLinkList.length > 1 || !!link.value}
                    onDelete={deleteExternalLink}
                  />
                ))}
              </div>

              <div className={cn('details__add-button-wrap')}>
                <button className={cn('details__add-button')} type="button" onClick={addEntityFactory(addExternalLink)}>
                  <icons.PlusIcon className={cn('details__plus-icon')} />
                  <div>Add link</div>
                </button>
              </div>
            </div>
            <div className={cn('details__group')}>
              <label htmlFor="design_investigator">
                Investigator name <span className={cn('required')}>*</span>
              </label>
              <Select
                value={currentEditFields.investigatorId}
                options={userOptionList}
                onChange={handleInvestigatorIdChange}
                theme={themeOptions.light}
                placeholder="Select investigator..."
                id="design_investigator"
                errorMessage={errors.investigatorId}
                className={cn('details__value', 'details__select', { details__select_error: errors.investigatorId })}
                controlClassName={cn('details__select-control')}
                menuClassName={cn('details__select-menu')}
              />

              <label htmlFor="design_project">Project name</label>
              <ProjectSelect
                selectedProjectId={currentEditFields.projectId ?? ''}
                setSelectedProjectId={handleProjectIdChange}
                placeholder="Select project..."
                id="design_project"
                errorMessage={errors.projectId}
                className={cn('details__value', 'details__select', { details__select_error: errors.projectId })}
                controlClassName={cn('details__select-control')}
              />

              <label htmlFor="design_organism">Organism</label>
              <div className={cn('details__multi-value')}>
                {organismList.map((organism, index) => (
                  <OrganismItem
                    key={organism.uuid}
                    value={organism.value}
                    uuid={organism.uuid}
                    onChange={changeOrganism}
                    inputId={index === 0 ? 'design_organism' : undefined}
                    isDeleteAllowed={organismList.length > 1 || !!organism.value}
                    onDelete={deleteOrganism}
                  />
                ))}
              </div>

              <div className={cn('details__add-button-wrap')}>
                <button className={cn('details__add-button')} type="button" onClick={addEntityFactory(addOrganism)}>
                  <icons.PlusIcon className={cn('details__plus-icon')} />
                  <div>Add organism</div>
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </PagePanel>
  );
};

export default DesignDetails;
