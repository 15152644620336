import { CagingSettingsItem, CCEType, CellFunction, Lane, LaneCagingSettings, Placement } from '@/graphql/API';

import { arrToMapByKeys, DEFAULT_SAMPLE_PREFIX, isKeyOf, LANE_LETTER_NAME_LIST } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import {
  TExperimentRunDesignState,
  TRunDesignCellType,
  TRunDesignComponent,
  TRunDesignLane,
  TRunDesignLaneCagingSettings,
} from '@/store/slices/experimentRunDesign/types';
import { DEFAULT_GLOBAL_SETTINGS, DEFAULT_MAGNIFICATION } from '@/pages/experiment-run-design/CagingSettings/constants';
import getCurrentComponentIndex from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getCurrentComponentIndex';
import getInitialIncubationComponent from '@/pages/experiment-run-design/DesignTimeline/reducer/helpers/getInitialIncubationComponent';

export const getAvailableId = (laneList: TRunDesignLane[], id?: string) => {
  if (id && LANE_LETTER_NAME_LIST.includes(id) && !laneList.find((lane) => lane.id === id)) {
    return id;
  }
  return LANE_LETTER_NAME_LIST.find((laneId) => !laneList.find((lane) => lane.id === laneId));
};

export const removeFromLanes = (lanes: TRunDesignLane[], laneId: string) => {
  const laneIndex = lanes.findIndex((lane) => lane.id === laneId);
  if (laneIndex >= 0) {
    lanes.splice(laneIndex, 1);
  }
};

export const removeLaneFromComponents = (components: TRunDesignComponent[], laneId: string) => {
  components.forEach((component) => {
    const laneIndex = component.performedOnLanes.findIndex((laneReagents) => laneReagents.laneId === laneId);
    if (laneIndex >= 0) {
      component.performedOnLanes.splice(laneIndex, 1);
    }
  });
};

// https://cellanome.atlassian.net/wiki/spaces/~7120209b519b0eacc44d9bb6fe3afb63f5becc/pages/644481058/Caging+Specifications
export const defineCellsConfigWithTwoCellTypes = (cellTypes: (TRunDesignCellType | null)[]) => {
  const preLabeledCellTypes = cellTypes.filter((cellType) => isDefined(cellType?.preLabeling?.consumable));

  const preLabeledCellTypesCount = preLabeledCellTypes.length;

  let cellToSubtract: Nullable<TRunDesignCellType> = null;
  let cellToCage: Nullable<TRunDesignCellType> = null;
  let cceType: CCEType = CCEType.ALL_SINGLE_CELLS;

  if (preLabeledCellTypesCount < 2) {
    cellToSubtract = preLabeledCellTypes?.[0] ?? null;
    cellToCage =
      preLabeledCellTypes?.[1] ??
      cellTypes.find((cellType) => cellToSubtract?.cellIndex?.id !== cellType?.cellIndex?.id) ??
      null;
  } else {
    cellToSubtract = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.TARGET) ?? null;
    cellToCage = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.EFFECTOR) ?? null;
    cceType = CCEType.SUBTRACTIVE_CCE;
  }

  return {
    cellToCage: cellToCage ?? null,
    cellToSubtract,
    cceType,
  };
};

export const defineCellsConfigWithThreeCellTypes = (cellTypes: (TRunDesignCellType | null)[]) => {
  const preLabeledCellTypes: (TRunDesignCellType | null)[] = [];
  const notPreLabeledCellTypes: (TRunDesignCellType | null)[] = [];

  cellTypes.forEach((cellType) => {
    if (isDefined(cellType?.preLabeling?.consumable)) {
      preLabeledCellTypes.push(cellType);
    } else {
      notPreLabeledCellTypes.push(cellType);
    }
  });

  const preLabeledCellTypesCount = preLabeledCellTypes.length;

  let cellToSubtract: Nullable<TRunDesignCellType> = null;
  let cellToCage: Nullable<TRunDesignCellType> = null;
  let cceType: CCEType = CCEType.ALL_SINGLE_CELLS;

  if (preLabeledCellTypesCount < 2) {
    cellToSubtract = preLabeledCellTypes?.[0] ?? null;

    const effectorCellType =
      notPreLabeledCellTypes.find(
        (cellType) => !isDefined(cellType?.preLabeling?.consumable) && cellType?.function === CellFunction.EFFECTOR
      ) ?? null;

    cellToCage = effectorCellType ?? null;
  } else {
    cellToSubtract = preLabeledCellTypes.find((cellType) => cellType?.function === CellFunction.TARGET) ?? null;
    const preLabeledEffectorCellType =
      preLabeledCellTypes.find(
        (cellType) =>
          cellToSubtract?.cellIndex?.id !== cellType?.cellIndex?.id && cellType?.function === CellFunction.EFFECTOR
      ) ?? null;

    cellToCage = preLabeledEffectorCellType ?? null;
    cceType = CCEType.SUBTRACTIVE_CCE;
  }

  if (!cellToCage) {
    notPreLabeledCellTypes.sort((current, next) => {
      const currentName = current?.cellIndex?.name ?? '';
      const nextName = next?.cellIndex?.name ?? '';
      return currentName.localeCompare(nextName);
    });

    [cellToCage] = notPreLabeledCellTypes;
  }

  return {
    cellToCage: cellToCage ?? null,
    cellToSubtract,
    cceType,
  };
};

export const defineLaneCellsConfigs = (lane: TRunDesignLane) => {
  if (!lane?.cellTypes?.length) {
    return {
      cellToCage: null,
      cellToSubtract: null,
      cceType: CCEType.ALL_SINGLE_CELLS,
    };
  }

  if (lane.cellTypes.length === 1) {
    return {
      cellToCage: lane.cellTypes[0],
      cellToSubtract: null,
      cceType: CCEType.ALL_SINGLE_CELLS,
    };
  }

  if (lane.cellTypes.length < 3) {
    return defineCellsConfigWithTwoCellTypes(lane.cellTypes);
  }

  return defineCellsConfigWithThreeCellTypes(lane.cellTypes);
};

export const updateLanesSettings = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.cagingSettings?.global || !state.editFields.current.schema.lanes) {
    return;
  }

  const lanesCagingSettingsMap = arrToMapByKeys(
    (state.editFields.current.schema?.cagingSettings.perLane?.filter((lane) =>
      isDefined(lane)
    ) as LaneCagingSettings[]) ?? [],
    'laneId'
  );

  state.editFields.current.schema.cagingSettings.perLane = state.editFields.current.schema.lanes.map((lane) => {
    if (!state.editFields.current.schema?.cagingSettings?.global) {
      return null;
    }

    const existedPerLane = lanesCagingSettingsMap?.[lane.id] ?? {};

    const overridesSettings = {
      ...state.editFields.current.schema.cagingSettings.global,
      ...existedPerLane?.overrideSettings,
    };

    const cellsConfig = defineLaneCellsConfigs(lane);

    const data: TRunDesignLaneCagingSettings = {
      __typename: 'LaneCagingSettings',
      laneId: lane.id,
      overrideSettings: getParsedCagingSettings(overridesSettings),
      magnification: existedPerLane.magnification ?? DEFAULT_MAGNIFICATION,
      ...cellsConfig,
    };

    return data;
  });
};

export const getParsedCagingSettings = (settings: CagingSettingsItem) => {
  const objCopy = structuredClone(settings);
  Object.keys(objCopy).forEach((key) => {
    if (isKeyOf(key, objCopy) && objCopy[key] === null && key !== '__typename') {
      objCopy[key] = DEFAULT_GLOBAL_SETTINGS[key];
    }
  });

  return objCopy;
};

// provide for the case when lanes are provided without cell Types, or when the number of cell Types in lane's differs
export const getParsedLanesData = (lanes?: Nullable<Lane[]>) => {
  const lanesCopy = structuredClone(lanes) ?? [];
  const cellTypesCountInPerLane = lanesCopy.map((lane) => lane.cellTypes?.length ?? 0);
  const maxCellTypesLength = Math.max(...cellTypesCountInPerLane);

  lanesCopy.forEach((lane) => {
    const cellTypes = Array.isArray(lane.cellTypes) ? lane.cellTypes : [null];

    const cellTypesLength = cellTypes.length;
    if (cellTypesLength < maxCellTypesLength) {
      lane.cellTypes = cellTypes.concat(Array(maxCellTypesLength - cellTypesLength).fill(null));
    }
  });
  return lanesCopy;
};

export const updateSampleNames = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.lanes?.length) {
    return;
  }

  state.editFields.current.schema.lanes = state.editFields.current.schema.lanes.map((lane) => {
    const sampleName = lane.sample?.trim() ? lane.sample : `${DEFAULT_SAMPLE_PREFIX}-${lane.id}`;
    return {
      ...lane,
      sample: sampleName,
    };
  });
};

export const clearGeneratedSampleNames = (state: TExperimentRunDesignState) => {
  if (!state.editFields.current.schema?.lanes?.length) {
    return;
  }

  state.editFields.current.schema.lanes = state.editFields.current.schema.lanes.map((lane) => {
    const sampleName = lane.sample === `Lane-${lane.id}` ? '' : lane.sample;
    return {
      ...lane,
      sample: sampleName,
    };
  });
};

export const getComponentListWithNewIncubation = (
  components: TRunDesignComponent[],
  id: string,
  placementIndex?: number
) => {
  const currentIncubationCount = components?.filter((component) => component.__typename === 'Incubation')?.length ?? 0;
  const firstRowComponentList = components?.filter(({ timing: { placement } }) => placement !== Placement.SIMULTANEOUS);
  const relativeTo = firstRowComponentList?.find((component) => component.id === id)
    ? id
    : firstRowComponentList?.at(-1)?.id;

  const incubationComponent = getInitialIncubationComponent(currentIncubationCount + 1, relativeTo);
  const componentIndex = placementIndex ?? getCurrentComponentIndex(components, id);

  return components?.toSpliced(componentIndex + 1, 0, incubationComponent);
};

export const removeIncubation = (components: TRunDesignComponent[]) => {
  // removes last incubation component from the list
  const incubationComponents = components?.filter((component) => component.__typename === 'Incubation') ?? [];
  const lastIncubationComponent = incubationComponents?.[incubationComponents.length - 1] ?? null;

  if (!lastIncubationComponent) return components;

  const fileteredComponentList = components.filter((component) => component.id !== lastIncubationComponent.id);

  return fileteredComponentList;
};
