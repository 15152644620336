import { MouseEventHandler } from 'react';
import { getEnum, TEnumValue } from '@/helpers/enum';

const designCardRendererVariants = ['template', 'draft', 'ready'] as const;

export const DesignCardRenderer = getEnum(designCardRendererVariants);

export type TDesignCardRenderer = TEnumValue<typeof DesignCardRenderer>;

const screenVariants = ['templates', 'drafts', 'ready'] as const;

export const ScreenVariantList = getEnum(screenVariants);

export type TScreenVariantList = TEnumValue<typeof ScreenVariantList>;

type TDefaultDesignCard = {
  id: string;
  title: string;
  assayList?: TAssay[];
  meta?: {
    key: string;
    value: string;
  }[];
  href?: string;
  controls?: {
    label: string;
    clickHandler: MouseEventHandler<HTMLButtonElement>;
  }[];
};

export type TTemplateDesignCardData = TDefaultDesignCard;

export type TDraftDesignCardData = TDefaultDesignCard & {
  steps: {
    current: number;
    total: number;
  };
};

export type TReadyDesignCardData = TDefaultDesignCard & {
  laneCount: number | string;
};

export type TDesignCardData = TTemplateDesignCardData | TDraftDesignCardData | TReadyDesignCardData;

const isTemplateDesignCardData = (cardData: TDesignCardData): cardData is TTemplateDesignCardData =>
  !(!('title' in cardData) || typeof cardData.title !== 'string');

export const isDraftDesignCardData = (cardData: TDesignCardData): cardData is TDraftDesignCardData => {
  if (!isTemplateDesignCardData(cardData)) {
    return false;
  }

  if (!('steps' in cardData)) {
    return false;
  }

  if ('current' in cardData.steps && 'total' in cardData.steps) {
    return typeof cardData.steps.current === 'number' && typeof cardData.steps.total === 'number';
  }

  return false;
};

export const isReadyDesignCardData = (cardData: TDesignCardData): cardData is TReadyDesignCardData => {
  if (!isTemplateDesignCardData(cardData)) {
    return false;
  }

  return 'laneCount' in cardData;
};
