import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OnChangeValue, SelectInstance } from 'react-select';

import { arrToMapByKeys } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { antnAPI } from '@/store/services/annotation';
import { TAnnotationType } from '@/store/services/annotation/endpoints/types';
import { TReturnTransformedTypes } from '@/store/services/annotation/dataProvider/types';

import { getNoOptionsMessage } from '@/components/common/Select/helpers';
import { MIN_SYMBOLS_TO_SEARCH_COUNT } from '@/components/common/Select/constants';

type TAnnotationKeys = keyof TReturnTransformedTypes;

type TUseAsyncSelectAnnotation<T> = {
  annotation?: Nullable<T>;
  onChange: (data: Nullable<T>) => void;
  annotationType: TAnnotationType | TAnnotationType[];
  annotationLabelKey: TAnnotationKeys;
  annotationValueKey: TAnnotationKeys;
  isAutoFocus?: boolean;
};

function useAsyncSelectAnnotation<T extends Record<string, any>>({
  annotation,
  onChange,
  annotationType,
  annotationLabelKey = 'name',
  annotationValueKey = 'name',
}: TUseAsyncSelectAnnotation<T>) {
  const [fetchAnnotations, { data: annotationsData }] = antnAPI.useLazyFetchAnnotationsQuery();
  const [isEditSelectMode, setIsEditSelectMode] = useState(!!annotation);
  const selectRef = useRef<Nullable<SelectInstance<TBasicOption>>>(null);
  const [selectedValue, setSelectedValue] = useState<Nullable<TBasicOption>>(null);

  const noOptionsMessage = useCallback((obj: { inputValue: string }) => <>{getNoOptionsMessage(obj)}</>, []);

  const handleSelectedNameChange = useCallback(
    (option: OnChangeValue<TBasicOption, boolean>) => {
      if (!option || !('value' in option)) {
        setSelectedValue(null);
        onChange(null);
        return;
      }
      const annotationsDataMap = arrToMapByKeys(annotationsData ?? [], annotationValueKey.toString());
      const valueStr = option.value.toString();
      onChange(annotationsDataMap[valueStr] as unknown as T);
    },
    [onChange, annotationsData, annotationValueKey]
  );

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: TBasicOption[]) => void) => {
      if (inputValue.length < MIN_SYMBOLS_TO_SEARCH_COUNT) {
        callback([]);
        return;
      }

      fetchAnnotations({ type: annotationType, search: inputValue }, true)
        .unwrap()
        .then((annotationList) => {
          const optionList = annotationList.map(
            (annotationItem): TBasicOption => ({
              label: annotationItem[annotationLabelKey],
              value: annotationItem[annotationValueKey]?.toString() ?? '',
              customData: annotationItem,
            })
          );
          callback(optionList);
        })
        .catch((error) => {
          showErrorToast(getErrorMessage(error));
          callback([]);
        });
    },
    [annotationType, annotationValueKey, annotationLabelKey]
  );

  useEffect(() => {
    if (!annotation || !(annotationLabelKey in annotation) || !(annotationValueKey in annotation)) {
      return setSelectedValue(null);
    }

    setSelectedValue({
      label: annotation[annotationLabelKey]?.toString() ?? '',
      value: annotation[annotationValueKey]?.toString() ?? '',
    });
  }, [annotation?.[annotationLabelKey], annotation?.[annotationValueKey]]);

  useEffect(() => {
    if (isEditSelectMode) {
      selectRef.current?.focus();
    } else {
      selectRef.current?.blur();
    }
  }, [isEditSelectMode]);

  const handleChangeEditMode = useCallback(() => {
    setIsEditSelectMode(true);
  }, []);

  const handleSetEditMode = useCallback((newStatus: boolean) => {
    setIsEditSelectMode(newStatus);
  }, []);

  return useMemo(
    () => ({
      handleChangeEditMode,
      loadOptions,
      noOptionsMessage,
      handleSelectedNameChange,
      selectedValue,
      isEditSelectMode,
      selectRef,
      handleSetEditMode,
    }),
    [
      handleChangeEditMode,
      loadOptions,
      noOptionsMessage,
      handleSelectedNameChange,
      selectedValue,
      isEditSelectMode,
      selectRef,
      handleSetEditMode,
    ]
  );
}

export default useAsyncSelectAnnotation;
