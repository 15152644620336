import { FC } from 'react';
import ReactAsyncSelect, { AsyncProps } from 'react-select/async';
import classNames from 'classnames/bind';

import selectStyles from '@/components/common/Select/Select.module.scss';

import styles from './SampleInformationAsyncSelect.module.scss';

const cn = classNames.bind({ ...selectStyles, ...styles });

type TSampleInformationAsyncSelect = {
  selectRef: any;
  menuClassName?: string;
} & AsyncProps<TBasicOption, boolean, any>;

const SampleInformationAsyncSelect: FC<TSampleInformationAsyncSelect> = ({
  selectRef,
  value,
  onChange,
  loadOptions,
  noOptionsMessage,
  components,
  menuClassName,
  ...otherProps
}) => (
  <ReactAsyncSelect
    {...otherProps}
    ref={selectRef}
    value={value}
    onChange={onChange}
    loadOptions={loadOptions}
    defaultOptions
    noOptionsMessage={noOptionsMessage}
    menuPortalTarget={document.body}
    menuPlacement="auto"
    placeholder=""
    components={{ DropdownIndicator: () => null, ...components }}
    className={cn('async-select__select')}
    classNames={{
      container: ({ isDisabled }) =>
        cn('select', 'select_light', {
          select_disabled: isDisabled,
        }),
      control: () => `${cn('select__control')} ${cn('async-select__select-control')}`,
      indicatorSeparator: () => cn('select__indicator-separator'),
      menu: () => cn('select__menu', 'select__menu_light', menuClassName),
      menuPortal: () => cn('select__menu-portal'),
      menuList: () => cn('select__menu-list', 'select__menu-list_light', menuClassName),
      option: ({ isFocused, isSelected, isMulti, isDisabled }) =>
        cn('select__option', {
          select__option_multiple: isMulti,
          select__option_focused: isFocused,
          select__option_selected: isSelected,
          select__option_disabled: isDisabled,
          'select__option_light-focus': isFocused,
        }),
      clearIndicator: () => cn('select__clear-indicator'),
      placeholder: () => cn('select__placeholder'),
      singleValue: () => cn('select__single-value'),
    }}
  />
);
export default SampleInformationAsyncSelect;
